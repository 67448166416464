import { Button, Col, Row } from "antd";
import { EDIT_TRADE_DETAILS_LABELS } from "constants/auditTrail";

import classnames from "classnames";

function BuyOrSellButtons({
  isSellActive,
  setIsSellActive,
  setIsTradeRequestFire,
  isLoading,
}) {
  return (
    <Col span={24} style={{ marginBottom: "10px" }}>
      <Row>
        <Col span={12}>
          <Button
            disabled={isLoading}
            style={{ width: "100%" }}
            onClick={() => {
              setIsSellActive(false);
              setIsTradeRequestFire((prev) =>
                prev === null ? true : prev === true ? prev : !prev
              );
            }}
            className={classnames({
              "active-button": isSellActive !== null && !isSellActive,
            })}
            key={EDIT_TRADE_DETAILS_LABELS.BUY}
          >
            {EDIT_TRADE_DETAILS_LABELS.BUY}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            disabled={isLoading}
            style={{ width: "100%" }}
            onClick={() => {
              setIsSellActive(true);
              setIsTradeRequestFire((prev) =>
                prev === null ? true : prev === true ? prev : !prev
              );
            }}
            className={classnames({
              "active-button": isSellActive !== null && isSellActive,
            })}
            key={EDIT_TRADE_DETAILS_LABELS.SELL}
          >
            {EDIT_TRADE_DETAILS_LABELS.SELL}
          </Button>
        </Col>
      </Row>
    </Col>
  );
}

export default BuyOrSellButtons;
