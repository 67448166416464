/* eslint-disable react-hooks/exhaustive-deps */
import { AgGridReact } from "ag-grid-react";
import { Row, Col } from "antd";

import { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import "ag-grid-enterprise";
import { GlobalContext } from "contexts/GlobalContext";
import "./gridStyles.css";
import { VOL_SURFACE_Control_COLS } from "constants/volSurface";
import { onGridReady } from "helpers/volSurfaceHelper";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { getRowClass, getRowStyle } from "constants/agGridCommonStyle";
import AgGridMonthEstimatesTable from "./AgGridMonthEstimatesTable";
import { getRowId } from "helpers/generalHelper";
import useUpdateControlTable from "./hooks/useUpdateControlTable";
import useControlTable from "./hooks/useControlTable";

function AgGridControlTable({ scrollableRef, start_date, end_date }) {
  const [gridHeight, setGridHeight] = useState("auto");
  const gridRef = useRef();
  const columnDefs = useMemo(() => VOL_SURFACE_Control_COLS, []);

  //custom hooks
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const {
    isLoading,
    selectedFilters,
    volSurfaceTablesData: rowsData,
    setTableHeight,
  } = useVolSurfaceControlTable();


  const { onCellEditingStopped } = useUpdateControlTable({
    scrollableRef,
    gridRef,
  });

  useControlTable({ start_date, end_date });
  //handle grid height
  useEffect(() => {
    if (rowsData?.length === 0) return;
    const numberOfRowData = rowsData?.length;
    const calculatedHeight = numberOfRowData * 30 + 95;
    setGridHeight(`${calculatedHeight}px`);
    setTableHeight(`${calculatedHeight}px`);
  }, [rowsData?.length, setTableHeight]);

  const gridOptions = {
    stopEditingWhenGridLosesFocus: false,
  };

  //change color of cell when editing
  gridOptions.onCellEditingStarted = function (event) {
    var editor = event.api.getCellEditorInstances({
      rowIndex: event.rowIndex,
      column: event.column,
    });
    if (editor && editor.length > 0) {
      editor[0].getGui().style.color = dark_Theme ? "white" : "black";
    }
  };

  if (isLoading || !selectedFilters?.product_id) return null;

  return (
    <Col
      xs={24}
      xxl={15}
      className={`mainVolSurfaceTable`}
      style={{ margin: "0px", padding: "0px" }}
    >
      <Row justify={"start"} style={{ width: "100%" }}>
        <div
          className={`${
            dark_Theme || false
              ? "pricer-ag-grid__container_dark"
              : "pricer-ag-grid__container"
          }`}
          style={{
            height: gridHeight,
            width: "85%",
            padding: "5px 0px 5px 10px",
          }}
        >
          <AgGridReact
            getRowId={getRowId}
            onCellEditingStopped={onCellEditingStopped}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            headerHeight={30}
            defaultColDef={{
              suppressMenu: true,
            }}
            rowHeight={30}
            rowData={rowsData}
            getRowStyle={(params) => {
              if (params.data.field !== "spread") {
                return {
                  borderTop: "none",
                  borderBottom: "none",
                };
              }
              getRowStyle(params);
            }}
            getRowClass={getRowClass}
            ref={gridRef}
            stopEditingWhenCellsLoseFocus={true}
            suppressMovableColumns={true}
            columnDefs={columnDefs}
            groupDefaultExpanded={1}
            suppressContextMenu
            className={`ag-theme-alpine pricer-ag-grid__table ${
              dark_Theme ? " ag-theme-balham-dark" : "ag-theme-balham"
            }`}
          />
        </div>
        <AgGridMonthEstimatesTable />
      </Row>
    </Col>
  );
}

export default memo(AgGridControlTable);
