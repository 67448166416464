import { Select, Col, Row } from "antd";
import { setLiveForUserVolSurface } from "api/volSurfaceApi";
import { VOL_SURFACE_GRAPGH_FILTERS } from "constants/volSurface";
import { getDataFromLocalStorage } from "helpers/generalHelper";
import { useEffect, useState } from "react";

export default function SetLiveSurface({
  dark_Theme,
  disabledCondition,
  setSelectedFilters,
  userVolSurfaces,
}) {

  const [liveGraphId, setLiveGraphId] = useState(
    getDataFromLocalStorage(VOL_SURFACE_GRAPGH_FILTERS)?.live_surface_id
  );

  useEffect(() => {
    if (liveGraphId) {
      let bodyParams = {
        vol_surface_id: liveGraphId,
      };
      setLiveForUserVolSurface(bodyParams);
    }
  }, [liveGraphId]);

  function onChangeHandle(e) {
    setLiveGraphId(e);
    setSelectedFilters((prev) => ({
      ...prev,
      live_surface_id: e,
    }));
  }

  function onClearHandle() {
    setLiveGraphId(null);
    setSelectedFilters((prev) => ({
      ...prev,
      live_surface_id: null,
    }));
  }

  return (
    <Col span={24}>
      <Row gutter={[25, 25]}>
        <Col span={3}>Set Live Surface:</Col>
        <Col>
          <Select
            placeholder="Select Surface"
            className={
              dark_Theme
                ? "content_dark select-dark ant-select-dark-disabled"
                : "content_light select-light ant-input-light-disabled"
            }
            popupClassName={dark_Theme ? "dropdown-dark" : ""}
            value={liveGraphId || undefined}
            style={{ width: 180 }}
            disabled={disabledCondition}
            onChange={(e) => onChangeHandle(e)}
            onClear={() => onClearHandle()}
            allowClear
            showSearch
            filterOption={(input, option) => {
              const inputSmallLetter = input.toLowerCase();
              const label = option.label.toLowerCase();
              return label.includes(inputSmallLetter);
            }}
            options={userVolSurfaces?.map((data) => ({
              value: data.id,
              label: data.title,
            }))}
          />
        </Col>
      </Row>
    </Col>
  );
}
