import { returnSelectedIdForEditTradeDetails } from "helpers/editTradeDetailsHelper";
import api from "./api";

const TEAM_RUN_END_POINT = "/team-run";
const UPDATE_LEGS_END_POINT = `${TEAM_RUN_END_POINT}/trade/update-legs`;
const TRADE_END_POINT = `${TEAM_RUN_END_POINT}/trade`;
const SHOW_TRADE_END_POINT = `${TRADE_END_POINT}/show`;
const UPDATE_PREMIUM_END_POINT = `${TRADE_END_POINT}/update-premium`;
const UPDATE_QUANTITY_END_POINT = `${TRADE_END_POINT}/update-quantity`;
const CONF_EMAIL_END_POINT = `trade/conf-email`;
const CONFIRM_TRADE_END_POINT = `/trader/trade-confirmation`;

export const updateTradeLegs = async (data) => {
  return await api.post(UPDATE_LEGS_END_POINT, {}, data);
};

export const getEditEditTRadeDetails = async (bodyParams) => {
  return await api.get(TRADE_END_POINT, { ...bodyParams });
};

export const refetchTradeDetails = async (bodyParams) => {
  return await api.get(SHOW_TRADE_END_POINT, { ...bodyParams });
};

export const updatePremiumTrade = async (params) => {
  return await api.post(UPDATE_PREMIUM_END_POINT, {},{...params});
};

export const updateQuantityTrade = async (data) => {
  return await api.post(UPDATE_QUANTITY_END_POINT, data);
};

export const storeOrUpdateTradeDetails = async (props, data) => {
  const endPointName = CONF_EMAIL_END_POINT;
  return await api.post(
    `${returnSelectedIdForEditTradeDetails(props, true)}/${endPointName}`,
    {},
    { ...data }
  );
};

export const confirmTrade = async (body) => {
  return await api.post(CONFIRM_TRADE_END_POINT, { ...body });
};
