import { AgGridReact } from "ag-grid-react"; // React Grid Logic

import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { GlobalContext } from "contexts/GlobalContext";
import "./gridStyles.css";
import { VOL_SURFACE_Month_Estimates_COLS } from "constants/volSurface";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { getRowClass, getRowStyle } from "constants/agGridCommonStyle";
import useUpdateEstimatesTable from "./hooks/useUpdateEstimatesTable";
import { getRowId } from "helpers/generalHelper";
import { onGridReady } from "helpers/volSurfaceHelper";

export default function AgGridMonthEstimatesTable() {
  const [gridHeight, setGridHeight] = useState("auto");
  const gridRef = useRef();
  const columnDefs = useMemo(() => VOL_SURFACE_Month_Estimates_COLS, []);

  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const { volSurfaceTablesData, isLoading, selectedFilters, volCalcTable } =
    useVolSurfaceControlTable();

  const { onCellEditingStopped } = useUpdateEstimatesTable({
    gridRef: gridRef,
  });

  const gridOptions = {
    stopEditingWhenGridLosesFocus: false,
  };

  gridOptions.onCellEditingStarted = function (event) {
    var editor = event.api.getCellEditorInstances({
      rowIndex: event.rowIndex,
      column: event.column,
    });
    if (editor && editor.length > 0) {
      editor[0].getGui().style.color = dark_Theme ? "white" : "black";
    }
  };

  useEffect(() => {
    if (!volSurfaceTablesData) {
      return;
    }
    const numberOfRowData = volSurfaceTablesData.length;
    const calculatedHeight = numberOfRowData * 30 + 95;
    setGridHeight(`${calculatedHeight}px`);
  }, [volSurfaceTablesData]);

 

  if (isLoading || !selectedFilters?.product_id) return null;

  return (
    <div
      className={`${
        dark_Theme || false
          ? "pricer-ag-grid__container_dark"
          : "pricer-ag-grid__container"
      }`}
      style={{
        height: gridHeight,
        width: "15%",
        padding: "5px 0px 5px 0px",
      }}
    >
      <AgGridReact
        getRowId={getRowId}
        onCellEditingStopped={(event, pricerTablesData) =>
          onCellEditingStopped(event, pricerTablesData)
        }
        headerHeight={30}
        rowHeight={30}
        rowData={volCalcTable}
        getRowStyle={getRowStyle}
        getRowClass={getRowClass}
        ref={gridRef}
        columnDefs={columnDefs}
        suppressContextMenu
        suppressMovableColumns={true}
        onGridReady={onGridReady}
        className={`ag-theme-alpine pricer-ag-grid__table ${
          dark_Theme ? " ag-theme-balham-dark" : "ag-theme-balham"
        }`}
      />
    </div>
  );
}
