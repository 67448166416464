import { EditOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { updateOnEditCell } from "api/pricerApi";
import { FIELD_NAMES } from "constants/pricerTable";
import { DARK_THEME } from "constants/profile";
import { PricerContext } from "contexts/PricerContext";
import { getDataFromLocalStorage } from "helpers/generalHelper";
import { updatedPricerData } from "helpers/Pricer/helperAggFunc";
import { isSublegRow } from "helpers/Pricer/helperFunc";
import React, { useContext } from "react";

const OptionTypeDropdown = (props) => {
  const {
    state: { pricerTablesData, pricerRefAgGrid, dateSelected },
    refetchOptionRow,
    setIsOptionRowLoading,
    setPricerData,
  } = useContext(PricerContext);
  const params = props;
  const node = params.node;
  const rowData = node.group ? node.aggData : node.data;
  // return isSublegRow(node) ? '' : rowData.type;
  const isDropdownVisible =
    rowData.option_types && rowData.option_types.length > 0;
  const isSummaryLevel = node.level === 0; // or whatever condition defines the first level
  if (isSublegRow(node)) {
    return "";
  }
  const dropdownOption = rowData?.option_types?.map((type) => ({
    label: type.label,
    key: type.value,
  }));
  const getLocalTheme = getDataFromLocalStorage(DARK_THEME);

  const handleMenuItemClick = (value) => {
    let bodyParams = {
      option_row_id: rowData.summary_id,
      summary_id: rowData.summary_id,
      option_type_id: value,
      calculation_date: dateSelected,
      row_type: 0,
    };
    setIsOptionRowLoading(true);
    updateOnEditCell(bodyParams)
      .then((res) => {
        const updatedData = updatedPricerData(res.data.data, pricerTablesData);
        setPricerData(updatedData);

        let refreshParams = {
          force: true, // forcefully refresh
          columns: [FIELD_NAMES.TYPE], // specific column to refresh
        };
        pricerRefAgGrid.api.refreshCells(refreshParams);
        setIsOptionRowLoading(false);
       
      })
      .catch((e) => {
        console.log("Error while updating option type ", e);
        setIsOptionRowLoading(false);
        refetchOptionRow();
      });
  };

  return (
    <>
      {isSummaryLevel && (
        <div className="type-wrapper action-buttons__container">
          <span>{rowData?.type}</span>
          {isDropdownVisible && (
            <span className="setting-dropdown">
              <Dropdown
                className={`action-buttons__dropdown button-icon `}
                overlayClassName={"setting-dropdown-menu"}
                menu={{
                  items: dropdownOption,
                  theme: getLocalTheme ? "dark" : "light",
                  onClick: ({ key }) => handleMenuItemClick(key),
                }}
                trigger={["click"]}
              >
                <EditOutlined />
              </Dropdown>
            </span>
          )}
        </div>
      )}
      {!isSummaryLevel && <span>{rowData?.type}</span>}
    </>
  );
};

export default OptionTypeDropdown;
