import {
  FIELD_NAMES,
  PRICER_NODES_COLORS,
  RowColor,
} from "constants/pricerTable";
import {
  extractCopyShortHandForSummaryRows,
  getDataFromLocalStorage,
  setDataToLocalStorage,
} from "../generalHelper";
import { COLORS } from "constants/agGridCommonStyle";
import { ACTION_BUTTONS_NAME } from "constants/generalActionButtons";
import { deleteRow, duplicateRow } from "api/pricerApi";
import { handleCopyShortHand, toast } from "../generalHelper";
import { ACTIONS, CATEGORIES, COMPONENT_NAMES, CONTEXTS, DESCRIPTIONS, ELEMENTS, EVENT_NAMES, sendEvent } from "../googleAnalyticsHelper";

export const handleColorMenu = (key, selectedRows, latestPropsRef) => {
  let nodesColorsArr = [];
  const nodesColorsArrSavedValue = getDataFromLocalStorage(PRICER_NODES_COLORS);
  if (nodesColorsArrSavedValue) {
    nodesColorsArr = nodesColorsArrSavedValue;
  }
  selectedRows.forEach((item) => {
    nodesColorsArr.push({ row_color: key, summary_id: item.summary_id });
  });
  const ids = nodesColorsArr?.map((i) => i.summary_id);
  const filterdColorsArray = nodesColorsArr?.filter(
    ({ summary_id }, index) => !ids.includes(summary_id, index + 1)
  );
  setDataToLocalStorage(PRICER_NODES_COLORS, filterdColorsArray);
  latestPropsRef.current.api.forEachNode(function (node) {
    const nodeData = node.group ? node.aggData : node.data;
    selectedRows.forEach((item) => {
      if (nodeData.summary_id === item.summary_id) {
        node.setDataValue(RowColor, key);
        latestPropsRef.current.api?.refreshCells({
          rowNodes: [node],
          columns: ["tenor"],
          force: true,
        });
      }
    });
  });
};

export const handleMenuItemClick = (
  itemClicked,
  selectedRows,
  latestPropsRef,
  refetchOptionRow,
  setIsOtherInfoModalVisible,
  setIsTradeClicked,
  onPushToRunClick,
  onPushToAuditTrailClick
) => {
  if (itemClicked === COLORS[3].name) {
    handleColorMenu(itemClicked, selectedRows, latestPropsRef);
    return;
  }
  if (
    itemClicked === COLORS[0].name ||
    itemClicked === COLORS[1].name ||
    itemClicked === COLORS[2].name
  ) {
    handleColorMenu(itemClicked, selectedRows, latestPropsRef);
    return;
  }
  const extractSummaryIds = selectedRows.map(
    (item) => item[FIELD_NAMES.SUMMARY_ID]
  );
  if (itemClicked === ACTION_BUTTONS_NAME.COPY_SHORTHAND.key) {
    extractCopyShortHandForSummaryRows(latestPropsRef.current);
    return;
  }

  if (itemClicked === ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key) {
    extractCopyShortHandForSummaryRows(latestPropsRef.current, false);
    return;
  }

  if (itemClicked === ACTION_BUTTONS_NAME.COPY_SHORTHAND_LEGS.key) {
    const rowData = latestPropsRef.current.node.group
      ? latestPropsRef.current.node.aggData
      : latestPropsRef.current.node.data;
    handleCopyShortHand(rowData.copy_shorthand_legs);
    return;
  }

  if (itemClicked === ACTION_BUTTONS_NAME.DUPLICATE.key) {
    duplicateRow(extractSummaryIds).then((res) => {
      refetchOptionRow();
      toast(res?.data?.message, "success");
       sendEvent({
         category: CATEGORIES.PRICER,
         description: DESCRIPTIONS.DUPLICATE,
         context: CONTEXTS.PRICER_ACTION_MENU,
         action: ACTIONS.CLICK,
         element: ELEMENTS.DUPLICATE,
         component: COMPONENT_NAMES.MENU_BUTTON,
         eventName: EVENT_NAMES.PRICER_ACTION_MENU_CLICK_DUPLICATE_MENU_BUTTON,
       });
    });
    return;
  }

  if (itemClicked === ACTION_BUTTONS_NAME.DELETE.key) {
    deleteRow(extractSummaryIds).then((res) => {
      
      refetchOptionRow();
      toast(res?.data?.message, "success");
    });
    return;
  }

  if (itemClicked === ACTION_BUTTONS_NAME.OTHER_INFO_AND_DETAILS.key) {
    setIsOtherInfoModalVisible(true);
    return;
  }

  if (itemClicked === ACTION_BUTTONS_NAME.TRADE.key) {
    setIsTradeClicked(true);
    return;
  }

  if (itemClicked === ACTION_BUTTONS_NAME.PUSH_TO_TEAM_RUN.key) {
    onPushToRunClick();
  }

  if (itemClicked === ACTION_BUTTONS_NAME.PUSH_TO_AUDIT_TRAIL.key) {
    onPushToAuditTrailClick();
  }
};
