import { useQuery } from "@tanstack/react-query";
import {
  fetchBespokeTenorAPI,
  fetchCommoditiesAPI,
  fetchConstraintsAPI,
  fetchContractStylesAPI,
  fetchTenorAPI,
  fetchTypes,
} from "api/pricerApi";
import { FIELDS_LABEL } from "constants/addOption";

const usePricerFetchFunctions = (addOptionData) => {
  const { data: commodityData, isLoading: commodityLoading } = useQuery(
    [FIELDS_LABEL.COMMODITY.key],
    async () => {
      const res = await fetchCommoditiesAPI();
      return res?.data.data.map((item) => ({
        label: item.title,
        value: item.id,
      }));
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: contractStyleData, isLoading: contractStyleLoading } = useQuery(
    [
      FIELDS_LABEL.CONTRACT_STYLE.key,
      {
        variable: addOptionData[FIELDS_LABEL.COMMODITY.key],
        skip: !addOptionData[FIELDS_LABEL.COMMODITY.key],
      },
    ],
    async () => {
      const res = await fetchContractStylesAPI(
        addOptionData[FIELDS_LABEL.COMMODITY.key]
      );
      return res.data.data.map((item) => ({
        value: item.title,
        label: item.title,
      }));
    },
    {
      enabled: !!addOptionData[FIELDS_LABEL.COMMODITY.key] === true,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { data: tenorsData } = useQuery(
    [
      FIELDS_LABEL.TENOR.key,
      { variable: addOptionData[FIELDS_LABEL.CONTRACT_STYLE.key] },
    ],
    async () => {
      const tenorData = await fetchTenorAPI(
        addOptionData[FIELDS_LABEL.CONTRACT_STYLE.key]
      );
      const objData = {};
      tenorData.data.data.forEach(
        (item) => (objData[item["period_id"]] = item.tenors)
      );
      return objData;
    },
    {
      enabled:
        !!addOptionData[FIELDS_LABEL.TYPE.key] === true &&
        !!addOptionData[FIELDS_LABEL.CONTRACT_STYLE.key] === true,
      refetchOnWindowFocus: false,
    }
  );
  const { data: bespokeTenors } = useQuery(
    [
      FIELDS_LABEL.BESPOKE_TENOR.key,
      { variable: addOptionData[FIELDS_LABEL.CONTRACT_STYLE.key] },
    ],
    async () => {
      const bespokeTenors = await fetchBespokeTenorAPI(
        addOptionData[FIELDS_LABEL.CONTRACT_STYLE.key]
      );

      const objData = bespokeTenors.data.data;
      return objData;
    },
    {
      enabled:
        !!addOptionData[FIELDS_LABEL.TYPE.key] === true &&
        !!addOptionData[FIELDS_LABEL.CONTRACT_STYLE.key] === true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: typesData, isLoading: typesLoading } = useQuery(
    [FIELDS_LABEL.TYPE.key, { variable: addOptionData[FIELDS_LABEL.LEGS.key] }],
    async () => {
      const result = await fetchTypes(addOptionData[FIELDS_LABEL.LEGS.key]);
      return result?.data.data.map((item) => ({
        label: item.title,
        value: item.id,
      }));
    },
    {
      enabled: !!addOptionData[FIELDS_LABEL.LEGS.key] === true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: constraintsData } = useQuery(
    [
      FIELDS_LABEL.CONSTRAINTS.key,
      { variable: addOptionData[FIELDS_LABEL.TYPE.key] },
      { variable: addOptionData[FIELDS_LABEL.CONTRACT_STYLE.key] },
    ],
    async () => {
      const constraintsData = await fetchConstraintsAPI(
        addOptionData[FIELDS_LABEL.TYPE.key],
        addOptionData[FIELDS_LABEL.LEGS.key],
        addOptionData[FIELDS_LABEL.CONTRACT_STYLE.key]
      );
      return constraintsData.data.data;
    },
    {
      enabled:
        !!addOptionData[FIELDS_LABEL.TYPE.key] === true &&
        !!addOptionData[FIELDS_LABEL.LEGS.key] === true,
      refetchOnWindowFocus: false,
    }
  );

  return {
    [FIELDS_LABEL.COMMODITY.key]: commodityData,
    [FIELDS_LABEL.CONTRACT_STYLE.key]: contractStyleData,
    [FIELDS_LABEL.TENOR.key]: tenorsData,
    [FIELDS_LABEL.TYPE.key]: typesData,
    [FIELDS_LABEL.CONSTRAINTS.key]: constraintsData,
    [FIELDS_LABEL.BESPOKE_TENOR.key]: bespokeTenors,
    commodityLoading,
    contractStyleLoading,
    typesLoading,
  };
};

export default usePricerFetchFunctions;
