/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { Dropdown, Space, Button, Spin } from "antd";
import { CopyOutlined, ImportOutlined, MenuOutlined } from "@ant-design/icons";
// Context
import { PricerContext } from "contexts/PricerContext";
// Components
import OtherInfoModal from "components/Pricer/OtherInfoModal";
import EditTradeDetails from "components/EditTradeDetails/EditTradeDetails";
import UpdateOption from "components/TeamRun/UpdateOption";
import PushOption from "components/TeamRun/PushOption";
import { GlobalContext } from "contexts/GlobalContext";
import { ACTION_BUTTONS_NAME } from "constants/generalActionButtons";

import PushToAuditTrail from "components/TeamRun/PushToAuditTrail/PushToAuditTrail";
import { handleMenuItemClick } from "helpers/Pricer/actionsButtons";
import { handleCopyShortHand } from "helpers/generalHelper";
// Helpers
import {
  ACTION_PRICER_BUTTONS_MENU_ITEMS,
  BULK_ACTIONS,
  FIELD_NAMES,
} from "constants/pricerTable";
// API
import { triggerPushOptionToTeamRunAPI } from "api/teamRunApi";

// Style
import "styles/PricerAuditAgGridLayout.scss";


const ToolTipWithChildren = ({ children }) => {
  return <>{children}</>;
};
const ActionButtons = (props) => {
  const isAggData = props.node.group ? props.node.aggData : props.node.data;
  const latestPropsRef = useRef(props);
  //Context
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const {
    refetchOptionRow,
    state: {
      selectedRows,
      isCellEditing,
      isPushingToTeamRunOrAuditTrail,
      isCellLocking,
      isOptionRowLoading,
      rowEditingData,
      refetchingRowsAfterCalcDateChange,
    },
    setIsPushingToTeamRunOrAuditTrail,
    isOptionRowFetching,
  } = useContext(PricerContext);

  const [rowData, setRowData] = useState({});
  const [isOtherInfoModalVisible, setIsOtherInfoModalVisible] = useState(false);
  const [isTradeClicked, setIsTradeClicked] = useState(false);
  const [isUpdateOptionClicked, setIsUpdateOptionClicked] = useState(false);
  const [isPushOptionClicked, setIsPushOptionClicked] = useState(false);
  const [isPushToAuditClicked, setIsPushToAuditClicked] = useState(false);
  const [popUpInfo, setPopUpInfo] = useState({
    type: "",
    data: "",
    colors: "",
    trader: "",
  });

  useEffect(() => {
    latestPropsRef.current = props;
  }, [props]);

  useEffect(() => {
    if (!props.data && props.node?.aggData) {
      const objOfData = {
        ...props.node?.aggData,
        [FIELD_NAMES.SUMMARY_ID]:
          props.node?.aggData[FIELD_NAMES.SUMMARY_REFERENCE_ID],
      };
      setRowData(objOfData);
    }
    if (props.data) {
      setRowData(props.data);
    }
  }, []);

  useEffect(() => {
    if (!isTradeClicked) return;
    refetchOptionRow();
  }, [isTradeClicked]);

  useEffect(() => {
    if (!isOtherInfoModalVisible) return;
    refetchOptionRow();
  }, [isOtherInfoModalVisible, isOtherInfoModalVisible]);

  const isActionButtonsDisabled =
    isAggData?.[FIELD_NAMES.SUMMARY_ID] ===
      rowEditingData?.[FIELD_NAMES.SUMMARY_ID] &&
    (isCellEditing ||
      isOptionRowFetching ||
      isCellLocking ||
      isOptionRowLoading);

  const isActionsDisabled =
    isActionButtonsDisabled ||
    refetchingRowsAfterCalcDateChange ||
    isPushingToTeamRunOrAuditTrail;

  const returnSelectedArray = [...ACTION_PRICER_BUTTONS_MENU_ITEMS].map(
    (item) => {
      item["disabled"] = isActionButtonsDisabled;
      return { ...item };
    }
  );

  const onPushToAuditTrailClick = async () => {
    setIsPushingToTeamRunOrAuditTrail(true);
    setIsPushToAuditClicked(true);
  };
  const onPushToRunClick = async () => {
    setIsPushingToTeamRunOrAuditTrail(true);
    try {
      const res = await triggerPushOptionToTeamRunAPI({
        summary_id: isAggData[FIELD_NAMES.SUMMARY_ID],
      });

      if (res.data.data["popup_type"] === 1) {
        setPopUpInfo({
          ...popUpInfo,
          type: res.data.data["popup_type"],
          data: res.data.data.rows,
          colors: res.data.data,
          tradingCompanies: res.data.data.trading_companies,
        });
        setIsUpdateOptionClicked(true);
        setIsPushOptionClicked(false);
      }

      if (res.data.data["popup_type"] === 2) {
        setPopUpInfo({
          ...popUpInfo,
          type: res.data.data?.["popup_type"],
          data: res?.data?.data,
          tradingCompanies: res.data.data.trading_companies,
        });
        setIsUpdateOptionClicked(false);
        setIsPushOptionClicked(true);
      }

      if (res.data.data["popup_type"] === 3) {
        setPopUpInfo({
          ...popUpInfo,
          type: res.data.data?.["popup_type"],
          data: res?.data?.data,
          tradingCompanies: res.data.data.trading_companies,
        });
        setIsUpdateOptionClicked(false);
        setIsPushOptionClicked(true);
      }

      setIsPushingToTeamRunOrAuditTrail(false);
    } catch (err) {
      console.log({ err });
      setIsPushingToTeamRunOrAuditTrail(false);
    }
  };

  const disabledClassName = (isDropdown) =>
    classnames(
      isDropdown
        ? "action-buttons__dropdown button-icon my-dropdown"
        : "button-icon",
      { "button-light-theme": !dark_Theme },
      {
        "button-icon-disabled": isActionsDisabled,
      }
    );

  if (props?.node?.level !== 0) return <></>;

  return (
    <div className="action-buttons__container action-button_container">
      {/*Show CopyShortHand*/}
      {isActionButtonsDisabled ? (
        <Space>
          <Spin size="small" />{" "}
        </Space>
      ) : (
        <>
          <ToolTipWithChildren
            title={
              !isActionButtonsDisabled &&
              ACTION_BUTTONS_NAME.COPY_SHORTHAND.label
            }
          >
            <Button
              disabled={isActionsDisabled}
              icon={<CopyOutlined />}
              onClick={() => {
                handleCopyShortHand(
                  props.node.group
                    ? props.node.aggData?.[FIELD_NAMES.COPY_SHORTHAND]
                    : props.node.data?.[FIELD_NAMES.COPY_SHORTHAND]
                );
              }}
              className={disabledClassName(false)}
            />
          </ToolTipWithChildren>

          {/*Show Pull To Pricer*/}
          <ToolTipWithChildren
            title={ACTION_BUTTONS_NAME.PUSH_TO_TEAM_RUN.label}
          >
            <Button
              disabled={isActionsDisabled}
              icon={<ImportOutlined className="rotate-import--icon" />}
              onClick={onPushToRunClick}
              className={disabledClassName(false)}
            />
          </ToolTipWithChildren>

          <Dropdown
            disabled={isActionsDisabled}
            className={disabledClassName(true)}
            trigger={["click"]}
            maskTransitionName="none"
            menu={{
              items:
                selectedRows.length < 2 ? returnSelectedArray : BULK_ACTIONS,
              theme: dark_Theme ? "dark" : "light",

              onClick: ({ key }) =>
                handleMenuItemClick(
                  key,
                  selectedRows,
                  latestPropsRef,
                  refetchOptionRow,
                  setIsOtherInfoModalVisible,
                  setIsTradeClicked,
                  onPushToRunClick,
                  onPushToAuditTrailClick
                ),
            }}
            overlayClassName={dark_Theme && "menu-dropdown-dark"}
          >
            <Space>
              <MenuOutlined />
            </Space>
          </Dropdown>
        </>
      )}

      {isTradeClicked && (
        <EditTradeDetails
          isPricer
          isOpen={isTradeClicked}
          handleMenuItemClicked={() => setIsTradeClicked((prev) => !prev)}
          rowSelectedInfo={props}
        />
      )}

      {isOtherInfoModalVisible && (
        <OtherInfoModal
          rowSelectedData={!props.data ? props.node.aggData : props.data}
          isModalOpen={isOtherInfoModalVisible}
          setOtherInfoModalVisible={setIsOtherInfoModalVisible}
        />
      )}

      {isUpdateOptionClicked && (
        <UpdateOption
          isOpen={isUpdateOptionClicked}
          onClose={setIsUpdateOptionClicked}
          data={popUpInfo.data}
          summaryId={isAggData[FIELD_NAMES.SUMMARY_ID]}
          colors={popUpInfo.colors}
          trader={popUpInfo.tradingCompanies}
        />
      )}

      {isPushOptionClicked && (
        <PushOption
          isOpen={isPushOptionClicked}
          onClose={setIsPushOptionClicked}
          data={popUpInfo.data}
          summaryId={isAggData[FIELD_NAMES.SUMMARY_ID]}
          tradingCompanies={popUpInfo.tradingCompanies}
        />
      )}

      {isPushToAuditClicked && (
        <PushToAuditTrail
          setIsPushingToTeamRunOrAuditTrail={setIsPushingToTeamRunOrAuditTrail}
          isOpen={isPushToAuditClicked}
          onClose={setIsPushToAuditClicked}
          isAggData={isAggData}
          data={props.node.group ? props.node.aggData : props.node.data}
          summaryId={isAggData[FIELD_NAMES.SUMMARY_ID]}
        />
      )}
    </div>
  );
};

export default ActionButtons;
