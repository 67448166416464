import StickyStrikeTable from "./StickyStrikeTable";

function StickyStrikeContainer({ currentTab }) {
  if (currentTab === "Sticky Delta") return;
  return (
    <div className="py-2 px-3">
      <StickyStrikeTable />
    </div>
  );
}

export default StickyStrikeContainer;
