import TraderState from "contexts/TraderContext";
import TraderAgGridTables from "components/Trader/TraderAgGridTables";

function Trader() {
    return (
    
        <TraderState>
          <TraderAgGridTables />
        </TraderState>
     
    );
}

export default Trader
