import axios from "axios";
import { GEEKS_USER } from "constants/profile";
import { getDataFromLocalStorage, localUserExist } from "helpers/generalHelper";
import { toast } from "helpers/generalHelper";
import * as Sentry from "@sentry/react";

// Get token from local storage
const getToken = () => {
  return getDataFromLocalStorage(GEEKS_USER)?.token || null;
};

// Common Axios Instance Config
const axiosConfig = {
  baseURL: process.env.REACT_APP_BASE_URL, // Development
};

// Create Default Axios Instances
const instance = axios.create(axiosConfig);
const instanceWithoutInterceptors = axios.create(axiosConfig);

// Interceptor for adding common headers to requests without authorization
instanceWithoutInterceptors.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = "en"; // Set content language header
  return config;
});

// Interceptor to add Authorization token for authenticated requests
instance.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`; // Set Authorization header
  }
  return config;
});

// Add interceptors for validate authorization
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error?.response || {};

    // Capture server errors in Sentry for production and staging environments
    if (
      process.env.REACT_APP_SENTRY_DNS &&
      status === 500 &&
      ["production", "staging"].includes(process.env.REACT_APP_NODE_ENV)
    ) {
      Sentry.captureException(error);
    }

    // Handle validation errors
    if (status === 422) {
      toast(data?.message, "warning");
    } else {
      if (error.code !== "ERR_CANCELED") {
        toast(data?.message || "Backend Error", "error");
      }
    }

    // Handle unauthorized errors (401)
    if (status === 401 && localUserExist()) {
      localStorage.clear();
      window.location.href = "/login";
    }

    return Promise.reject(new Error(error));
  }
);

// Export Axios with Auth Headers
export default instance;

// Export Axios Without instance
export { instanceWithoutInterceptors as axiosWI };
