import {
  fetchFilterShortCutsForVolSurface,
  fetchFiltersForVolSurface,
} from "api/volSurfaceApi";
import { Sticky_Delta_Strike_LABELS } from "constants/generalActionButtons";
import { VOL_SURFACE_GRAPGH_FILTERS, VOL_SURFACE_STICKY_DELTA_OR_STRIKE } from "constants/volSurface";
import { getDataFromLocalStorage } from "helpers/generalHelper";
import {
  createContext,
  useCallback,
  useLayoutEffect,
  useMemo,
  useReducer,
} from "react";
import { useLocation } from "react-router-dom";
import { ROUTES_PATH } from "routes";

// List of initial state for all global data
export const initialState = {
  volSurfaceData: null,
  volSurfaceFilters: null,
  volSurfaceFilterShortcuts: null,
  isVolSurfaceLoading: false,
  userVolSurfaces: null,
  volSurfaceBtnLoading: false,
  isShortcutsLoading: false,
  selectedFilters: {},
};

// Actions
const SET_VOL_SURFACE_DATA = "SET_VOL_SURFACE_DATA";
const SET_VOL_SURFACE_FILTERS = "SET_VOL_SURFACE_FILTERS";
const SET_VOL_SURFACE_FILTER_shortcuts = "SET_VOL_SURFACE_FILTER_shortcuts";
const SET_USER_VOL_SURFACES = "SET_USER_VOL_SURFACES";
const VOL_SURFACE_BTN_LOADING = "VOL_SURFACE_BTN_LOADING";
const SET_SELECTED_FILTER = "SET_SELECTED_FILTER";
const SET_IS_SHORTCUTS_LOADING = "SET_IS_SHORTCUTS_LOADING";

// Shared Reducer For Global Context
const Reducer = (state, action) => {
  switch (action.type) {
    case SET_VOL_SURFACE_DATA:
      return {
        ...state,
        volSurfaceData: action.payload,
      };
    case SET_VOL_SURFACE_FILTERS:
      return {
        ...state,
        volSurfaceFilters: action.payload,
      };
    case SET_IS_SHORTCUTS_LOADING:
      return {
        ...state,
        isShortcutsLoading: action.payload,
      }
    case SET_VOL_SURFACE_FILTER_shortcuts:
      return {
        ...state,
        volSurfaceFilterShortcuts: action.payload,
      };
    case SET_USER_VOL_SURFACES:
      return {
        ...state,
        userVolSurfaces: action.payload,
      };
    case VOL_SURFACE_BTN_LOADING:
      return {
        ...state,
        volSurfaceBtnLoading: action.payload,
      };
    case SET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilters: action.payload,
      };
    default:
      return state;
  }
};

// Global State Which its provide context for children
const VolSurfaceState = ({ children, defaultInitialState = {} }) => {
  const currentTab = getDataFromLocalStorage(VOL_SURFACE_STICKY_DELTA_OR_STRIKE);
  const location = useLocation();
  const [state, dispatch] = useReducer(Reducer, {
    ...initialState,
    ...defaultInitialState,
  });

  const setIsShortcutsLoading = useCallback((value) => {
    dispatch({ type: SET_IS_SHORTCUTS_LOADING, payload: value });
  }, []);

  const setVolSurfaceData = useCallback((value) => {
    dispatch({ type: SET_VOL_SURFACE_DATA, payload: value });
  }, []);

  const setVolSurfaceFilters = useCallback((value) => {
    dispatch({ type: SET_VOL_SURFACE_FILTERS, payload: value });
  }, []);

  const setVolSurfaceFilterShortcuts = useCallback((value) => {
    dispatch({ type: SET_VOL_SURFACE_FILTER_shortcuts, payload: value });
  }, []);

  const setUserVolSurfaces = useCallback((value) => {
    dispatch({ type: SET_USER_VOL_SURFACES, payload: value });
  }, []);

  const setVolSurfaceBtnLoading = useCallback((value) => {
    dispatch({ type: VOL_SURFACE_BTN_LOADING, payload: value });
  }, []);

  const setSelectedFilters = useCallback((value) => {
    dispatch({ type: SET_SELECTED_FILTER, payload: value });
  }, []);

  // The below useLayoutEffect for fetching the filters from API for Vol-Surface.
  // We have 3 filters for now and I used the PROMISE CHAINING method to fetch them one by one.
  // 1. Commodity   2. Contract-Style   3. Period Type
  // The first api call will fetch only the Commodity filter (because it's not depend on any other filter)
  // The 2nd filter(COntract-Style) depends on the first(Commodity) and the third Filter(Period Type) depends on the 2nd(Contract-Style)
  // we have store selected Filters as "volSurface_graphFilters" for Vol-surface in local storage and getting the filters to check either we need to fetch the other 2 filters or not.
  // The second filter api (Contract-Style) will work if the user already select any option from the first filter (Commodity) otherwise it will simply store the filters in the Vol-Surface context.
  // Similarly, we have the third filter API that will only call if the user select any option from the 2nd filter.

  useLayoutEffect(() => {
    if (!state.volSurfaceFilters) {
      let localFilterData = getDataFromLocalStorage(VOL_SURFACE_GRAPGH_FILTERS);
      fetchFiltersForVolSurface()
        .then((res) => {
          if (res.data?.data) {
            let newData = res.data.data.map((filter) => {
              if (filter.key === "contract_style") {
                return {
                  ...filter,
                  data: [],
                };
              } else {
                return filter;
              }
            });
            return newData;
          }
        })
        .then((newData) => {
          setVolSurfaceFilters(newData);
          return null;
        });
    }
  }, [state.volSurfaceFilters]);

const controller = new AbortController();

  useLayoutEffect(() => {
  if(!location.pathname.includes(ROUTES_PATH.MARKET_ANALYSIS.index)){
        setIsShortcutsLoading(true);
        setVolSurfaceFilterShortcuts(null);
        fetchFilterShortCutsForVolSurface({
          is_x_axis_delta:
            currentTab === Sticky_Delta_Strike_LABELS.STICKY_Delta ? 1 : 0
        },controller.signal)
          .then((res) => {
            setVolSurfaceFilterShortcuts(res.data.data);
            setIsShortcutsLoading(false);
          })
          .catch((err) => {
            setIsShortcutsLoading(false);
            console.log(
              "error while fetching vol-surface filter shortcuts",
              err
            );
          });
  }
  return () => {
    controller.abort();
  }
    
  }, [currentTab,location.pathname]);

  const contextValue = useMemo(() => {
    return {
      state,
      setVolSurfaceData,
      setVolSurfaceFilters,
      setUserVolSurfaces,
      setVolSurfaceBtnLoading,
      setVolSurfaceFilterShortcuts,
      setSelectedFiltersData: setSelectedFilters,
    };
  }, [state]);

  return (
    <VolSurfaceContext.Provider value={contextValue}>
      {children}
    </VolSurfaceContext.Provider>
  );
};

// Create Global Context
export const VolSurfaceContext = createContext(initialState);

// Export Global State Context Component
export default VolSurfaceState;
