import api from "./api";

const VOL_SURFACE_END_POINT = "/vol-surface";

//// DELTA SURFACES APIs ////
const DELTA_SURFACES_END_POINT = `${VOL_SURFACE_END_POINT}/delta-surfaces`;
const GRAPHS_END_POINT = `${DELTA_SURFACES_END_POINT}/graphs`;
const UPDATE_BATCH_END_POINT = `${DELTA_SURFACES_END_POINT}/update-batch`;
const GRAPHS_FILTER_END_POINT = `${GRAPHS_END_POINT}/filters`;
const SHORTCUTS_END_POINT = `${DELTA_SURFACES_END_POINT}/shortcuts`;
const EXPORT_DATE_END_POINT = `${DELTA_SURFACES_END_POINT}/export-date`;
const SURFACES_END_POINT = `${DELTA_SURFACES_END_POINT}/surfaces`;
const STORE_DELTA_SURFACE_END_POINT = `${DELTA_SURFACES_END_POINT}/store`;
const SET_LIVE_END_POINT = `${DELTA_SURFACES_END_POINT}/set-live`;
const DELTA_CONTROL_TABLE_END_POINT = `${DELTA_SURFACES_END_POINT}/control-table`;
const VOL_CALC_TABLE_END_POINT = `${DELTA_CONTROL_TABLE_END_POINT}/vol-calc-table`;

//// STRIKE SURFACES APIs ////
const STRIKE_SURFACES_END_POINT = `${VOL_SURFACE_END_POINT}/strike-surfaces`;
const STRIKE_CONTROL_TABLE_END_POINT = `${STRIKE_SURFACES_END_POINT}/control-table`;
const STORE_STRIKE_CONTROL_TABLE_END_POINT = `${STRIKE_CONTROL_TABLE_END_POINT}/store`;
const UPDATE_STRIKE_CONTROL_TABLE_END_POINT = `${STRIKE_CONTROL_TABLE_END_POINT}/update`;
const STORE_STRIKE_SURFACES_END_POINT = `${STRIKE_CONTROL_TABLE_END_POINT}/store`;
const DELETE_STRIKE_SURFACES_END_POINT = `${STRIKE_CONTROL_TABLE_END_POINT}/delete`;
const ADD_EMPTY_STRIKE_ROW_END_POINT = `${STRIKE_CONTROL_TABLE_END_POINT}/add-empty-row`;
const GET_STRIKE_SURFACES_END_POINT = `${STRIKE_CONTROL_TABLE_END_POINT}/get-strike-surfaces`;

//// DATA TEAM APIs ////
const DATA_TEAM_END_POINT = `${VOL_SURFACE_END_POINT}/data-team`;
const DATA_TEAM_SURFACES_END_POINT = `${DATA_TEAM_END_POINT}/surfaces`;
const DATA_TEAM_EXPORT_DATE_END_POINT = `${DATA_TEAM_END_POINT}/export`;

export const getGraphsData = async (param) => {
  return await api.get(GRAPHS_END_POINT, { ...param });
};

export const fetchTrailDataForVolSurface = async (queryParams) => {
  return await api.get(VOL_SURFACE_END_POINT, { ...queryParams });
};

export const batchUpdateGraphs = async (body) => {
  return await api.post(UPDATE_BATCH_END_POINT, { ...body });
};

export const fetchFiltersForVolSurface = async () => {
  return await api.get(GRAPHS_FILTER_END_POINT);
};

export const fetchFilterShortCutsForVolSurface = async (queryParams,signal) => {
  return await api.get(SHORTCUTS_END_POINT, {...queryParams} ,{},signal);
};

export const sendEmailForVolSurface = async () => {
  return await api.get(EXPORT_DATE_END_POINT);
};

export const fetchUserVolSurface = async (queryParams) => {
  return await api.get(SURFACES_END_POINT, { ...queryParams });
};

export const updateUserVolSurfaceTitle = async (body, isSticky) => {
  return await api.post(
    isSticky
      ? STORE_STRIKE_CONTROL_TABLE_END_POINT
      : STORE_DELTA_SURFACE_END_POINT,
    { ...body }
  );
};

export const setLiveForUserVolSurface = async (body) => {
  return await api.post(SET_LIVE_END_POINT, { ...body });
};

export const updateOnEditCell = async (body) => {
  return await api.post(DELTA_CONTROL_TABLE_END_POINT, { ...body });
};
export const updateOnEditEstimateCell = async (body) => {
  return await api.post(VOL_CALC_TABLE_END_POINT, { ...body });
};

export const getControlTableData = async (param) => {
  return await api.get(DELTA_CONTROL_TABLE_END_POINT, { ...param });
};

export const getVolSurfaceData = async (param) => {
  return await api.get(DATA_TEAM_END_POINT, { ...param });
};

export const getSurfacesData = async (param) => {
  return await api.get(DATA_TEAM_SURFACES_END_POINT, { ...param });
};

export const downloadVolSurfaceData = async (params) => {
  return await api.get(DATA_TEAM_EXPORT_DATE_END_POINT, { ...params });
};

export const getVolSurfaceStickyStrikes = async (queryParams) => {
  return await api.get(STRIKE_CONTROL_TABLE_END_POINT, { ...queryParams });
};
export const getStrikeSurfaces = async (queryParams) => {
  return await api.get(GET_STRIKE_SURFACES_END_POINT, { ...queryParams });
};

export const updateStickyStrikeCell = async (body) => {
  return await api.post(UPDATE_STRIKE_CONTROL_TABLE_END_POINT, { ...body });
};

export const saveStickyStrikeCell = async (body) => {
  return await api.post(STORE_STRIKE_SURFACES_END_POINT, { ...body });
};

export const deleteRow = async (summary_ids) => {
  return await api.post(DELETE_STRIKE_SURFACES_END_POINT, {}, {
    rows: summary_ids,
  });
};

export const addEmptyStrikeRow = async (body) => {
  return await api.post(ADD_EMPTY_STRIKE_ROW_END_POINT, {}, { ...body });
};
