import { Divider, Form, InputNumber, Radio } from "antd";
import React, { useMemo } from "react";
import { BUTTON_NAMES, FIELDS_LABEL, INPUT_KEYS } from "constants/addOption";

const { Item } = Form;

export const FormItem = (props) => {
  return (
    <>
      <Item label={ props.label }>
        { props.children }
      </Item>
      <Divider />
    </>
  );
};

export const InputNumberWithRadioButton = (props) => {
  const getStrikesButtons = useMemo(() => {
    if(props.label === FIELDS_LABEL.STRIKE.label) {
      if(props.data['is_call'] === null){
        return (
          <div className="group-of-button">
            <Radio.Button value={BUTTON_NAMES.PUT}> {BUTTON_NAMES.PUT} </Radio.Button>
            <Radio.Button value={BUTTON_NAMES.CALL}> {BUTTON_NAMES.CALL} </Radio.Button>
          </div>
        )
      }

      if(props.data['is_call']) {
        return (
          <Radio.Button
            className="selected-button"
            onChange={(value)=>props.onChange(INPUT_KEYS.BUTTON, BUTTON_NAMES.CALL)}
          >
            {BUTTON_NAMES.CALL}
          </Radio.Button>
        )
      }

      if(!props.data['is_call']) {
        return (
          <Radio.Button
            className="selected-button"
            onChange={(value)=>props.onChange(INPUT_KEYS.BUTTON, BUTTON_NAMES.PUT)}
          >
            {BUTTON_NAMES.PUT}
          </Radio.Button>
        )
      }
    }
  },[props.label]);

  const getRatioButton = useMemo(() => {
    if(props.label === FIELDS_LABEL.RATIO.label) {
      if(props.data['is_buy'] === null){
        return (
          <div className="group-of-button">
            <Radio.Button value={BUTTON_NAMES.BUY}>{BUTTON_NAMES.BUY}</Radio.Button>
            <Radio.Button value={BUTTON_NAMES.SELL}>{BUTTON_NAMES.SELL}</Radio.Button>
          </div>
        )
      }

      if(props.data['is_buy']) {
        return (
          <Radio.Button
            className="selected-button"
            onChange={(value)=>props.onChange(INPUT_KEYS.BUTTON, BUTTON_NAMES.BUY)}
          >
            {BUTTON_NAMES.BUY}
          </Radio.Button>
        )
      }

      if(!props.data['is_buy']) {
        return (
          <Radio.Button
            className="selected-button"
            onChange={(value)=>props.onChange(INPUT_KEYS.BUTTON, BUTTON_NAMES.SELL)}
          >
            {BUTTON_NAMES.SELL}
          </Radio.Button>
        )
      }
    }
  },[props.label]);

  return (
    <div className="strike-single-field">
      <InputNumber
        onPressEnter={event => event.target.blur()}
        className="ratio-and-strike__input-number"
        min={ 1 }
        value={props.value}
        disabled={props.isDisabled}
        onChange={ (value) => props.onChange(INPUT_KEYS.VALUE, value) }
      />

      <Radio.Group buttonStyle="solid"  onChange={ (e) => props.onChange(INPUT_KEYS.BUTTON, e.target.value) }>
        {props.label ? props.label === FIELDS_LABEL.STRIKE.label ? getStrikesButtons : getRatioButton : <></>}
      </Radio.Group>
    </div>
  )
};

export const RadioGroupButtons = ({ firstButton = "", lastButton = "", ...props }) => {
  return (
    <Radio.Group
      defaultValue={props.defaultValue}
      className="radio-group__container"
      size="small"
      buttonStyle="solid"
      onChange={(e)=>props.onChange(e.target.value)}
    >
      <Radio.Button value={ firstButton }>
        { firstButton }
      </Radio.Button>
      <Radio.Button value={lastButton}>
        { lastButton }
      </Radio.Button>
    </Radio.Group>
  )
};
