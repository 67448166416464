import _ from "lodash";
import {
  FIELD_NAMES,
  PRICER_COLUMNS,
  PRICER_KEYS_NOT_INCLUDED,
} from "constants/pricerTable";
import { PRICER_OPT_INFO } from "constants/profile";
import {
  PRICER_EXPANDED_ROWS,
  expandedMatchedRows,
  getDataFromLocalStorage,
} from "../generalHelper";
import { SUMMARY_IDS } from "components/Pricer/FilterButtons/FilterButtons";
import {
  cellStyleFunc,
  editableColumnsFunc,
  storeInLocalStorage,
} from "./Table";
import { LightThemeStyle } from "constants/agGridCommonStyle";

export const PRICER_DATAFunc = () => getDataFromLocalStorage(PRICER_OPT_INFO);
export const PRICER_SELECTED_ROWS = "pricer_selectedRows";
export const PRICER_FILTER_BUTTON_SELECTED = "pricer_filterButtonSelected";
export const PRICER_DATE_SELECTED = "pricer_dateSelected";
export const PRICER_SORTED_COLUMNS = "pricer_sortedColumns";

export const selectRowBasedOnSummaryId = (gridAPI) => {
  const pricerData = PRICER_DATAFunc();
  if (!pricerData[PRICER_SELECTED_ROWS]?.length) return;

  pricerData[PRICER_SELECTED_ROWS].forEach((summaryId) => {
    gridAPI?.forEachNode((node) => {
      const nodeData = node.group ? node.aggData : node.data;
      if (nodeData[FIELD_NAMES.SUMMARY_ID] === summaryId) {
        node.setSelected(true);
      }
    });
  });
};

export const expandRowBasedOnSummaryId = (gridAPI) => {
  const pricerData = PRICER_DATAFunc();

  if (_.isEmpty(pricerData?.[PRICER_EXPANDED_ROWS])) return;

  Object.values(pricerData[PRICER_EXPANDED_ROWS]).forEach((keyItem) => {
    expandedMatchedRows(gridAPI, keyItem);
  });
};

/**
 * Exports the data from the Pricer grid as a CSV file.
 * @param {Object} gridRef - A reference to the Pricer grid component.
 */
export const onPricerBtnExport = (gridRef) => {
  let rows = [];
  gridRef.current.api.forEachNode((node) => {
    // Create a deep copy of originalRowData
    const originalRowData = node.group ? node.aggData : node.data;
    const rowData = JSON.parse(JSON.stringify(originalRowData));
    // Add this debug log

    PRICER_KEYS_NOT_INCLUDED.forEach((key) => delete rowData[key]);
    rows.push(rowData);
  });

  const options = rows.reduce(function (rv, x) {
    (rv[x["summary_id"]] = rv[x["summary_id"]] || []).push(x);
    return rv;
  }, {});

  let csvContent = "data:text/csv;charset=utf-8,";

  Object.values(options).forEach((option, key) => {
    if (key === 0) {
      const columns = Object.keys(rows[0]);
      csvContent += columns.map((col) => `"${col}"`).join(",") + "\r\n"; // Escaping column names
    }

    option.forEach(function (rowObject) {
      let rowArray = Object.keys(rows[0]).map((col) => rowObject[col] || ""); // Consistent column order
      let row = rowArray
        .map((item) => `"${item.toString().replace(/"/g, '""')}"`) // Escape special characters
        .join(",");
      csvContent += row + "\r\n";
    });

    const emptyRow = Array.from(rows[0])
      .map(() => "")
      .join(",");
    csvContent += emptyRow + "\r\n";
  });

  var encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
};

/**
 * Flashes the cells in the Pricer table that have new summary IDs.
 * @param {Object} gridRef - The reference to the Pricer table grid.
 * @param {Array} pricerTablesData - The data of the Pricer tables.
 */
export const onPricerCellFlash = (
  gridRef,
  pricerTablesData,
) => {
  const savedSummaryIds = getDataFromLocalStorage(SUMMARY_IDS);
  if (!savedSummaryIds) return;
  const currentSummaryIds =
    pricerTablesData && pricerTablesData?.map((item) => item.summary_id);
  const newSummaryId =
    currentSummaryIds &&
    currentSummaryIds?.filter((item) => !savedSummaryIds.includes(item));
  let rowsWithNewSummaryId = [];
  newSummaryId.length &&
    newSummaryId?.forEach((id) => {
      gridRef.current?.api?.forEachNode((node) => {
        const nodeData = node.group ? node.aggData : node.data;
        if (!nodeData) return;
        if (nodeData.summary_id === id) rowsWithNewSummaryId.push(node);
      });
    });
  if (!rowsWithNewSummaryId || rowsWithNewSummaryId.length === 0) return;
  setTimeout(() => {
    gridRef.current?.api.flashCells({ rowNodes: rowsWithNewSummaryId });
  }, 500);
  storeSummaryIdsInLocalStorage(pricerTablesData);
};

export const storeSummaryIdsInLocalStorage = (pricerTablesData) => {
  const summaryIds = pricerTablesData?.map((item) => item.summary_id);
  storeInLocalStorage(SUMMARY_IDS, JSON.stringify(summaryIds));
};

export const pricerColumnsData = (dark_Theme, editable, rowEditingData) => {
  const initial = getDataFromLocalStorage("greeksDropDown");

  return [...PRICER_COLUMNS(initial)].map((item) => ({
    ...item,
    menuTabs: [],
    width: null,
    editable: (event) => editableColumnsFunc(event, editable, rowEditingData),
    cellStyle: (params) => {
      if (!dark_Theme) {
        return LightThemeStyle;
      } else {
        return cellStyleFunc(params);
      }
    },
  }));
};

export const setPricerModalSort = (params, stateToSort) => {
  params.columnApi.applyColumnState({
    state: stateToSort,
    defaultState: { sort: stateToSort },
  });
};

/**
 * A function that retrieves the row ID from the given parameters.
 * @param {object} params - the parameters containing the data and node
 * @return {string} the ID of the row
 */
export const getPricerRowId = (params) => {
  if (!params.data) {
    return params.node.id;
  }
  return params.data.id;
};

export const onSaveInGlobalContext = (gridApi, setPricerOperation) => {
  const pricerDataFromLocalStorage = (() => PRICER_DATAFunc())();
  const selectedSummaryId = gridApi
    .getBestCostNodeSelection()
    .map((item) =>
      item?.group
        ? item.aggData?.[FIELD_NAMES.SUMMARY_ID]
        : item?.data?.[FIELD_NAMES.SUMMARY_ID]
    );
  setPricerOperation({
    ...pricerDataFromLocalStorage,
    [PRICER_SELECTED_ROWS]: selectedSummaryId?.length
      ? selectedSummaryId
      : null,
  });
};

export const onPricerSortChanged = (params, setPricerOperation) => {
  const columnState = params.columnApi.getColumnState();
  const pricerDataFromLocalStorage = (() => PRICER_DATAFunc())();
  const sortedColumns = columnState
    .filter((item) => item.sort !== null)
    .map((item) => ({
      sort: item.sort,
      colId: item.colId,
      sortIndex: item.sortIndex,
    }));
  setPricerOperation({
    ...pricerDataFromLocalStorage,
    [PRICER_SORTED_COLUMNS]: sortedColumns && sortedColumns,
  });

  //---------------- set the sort model to sort by summary id if there is no sort -- this overrides the default sort by  ag grid that puts ungrouped rows before grouped rows
  // Filter the column state to find the columns with sorting applied
  const notSorted = columnState.filter((column) => column.sort).length === 0;

  // Check if there is no sorting applied
  if (notSorted) {
    // Find the specific column in the column state
    const specificColumn = columnState.find(
      (column) => column.colId === "summary_id"
    );
    // Apply the sorting on the specific column
    specificColumn.sort = "desc"; // Change this to the desired sorting order ('asc', 'desc', or null)
    // Update the column state in the grid
    params.columnApi.applyColumnState({ state: columnState });
  }
};

export const pricerPrepareDataForCellEditing = (
  rowData,
  event,
  dateSelected
) => {
  const isTenor = event.colDef.field === "tenor";
  const prepareBody = {
    [FIELD_NAMES.ROW_TYPE]: rowData.row_type,
    [FIELD_NAMES.LEG_ID]: rowData[FIELD_NAMES.LEG_ID],
    [FIELD_NAMES.SUMMARY_ID]: rowData[FIELD_NAMES.SUMMARY_ID],
    [FIELD_NAMES.OPTION_ROW_ID]: rowData.id,
    [FIELD_NAMES.CALCULATION_DATE]: dateSelected,
    [isTenor ? "style_period_tenor_id" : event.colDef.field]: isTenor
      ? +event.data.style_period_tenor_id
      : +event.newValue,
  };

  return prepareBody;
};

export const onPricerCellEditingStarted = (
  event,
  setRowEditingData,
  dark_Theme
) => {
  const node = event.node;
  const rowData = node.group ? node.aggData : node.data;
  var editor = event.api.getCellEditorInstances({
    rowIndex: event.rowIndex,
    column: event.column,
  });
  if (editor && editor.length > 0) {
    editor[0].getGui().style.color = dark_Theme ? "white" : "black";
  }
  setRowEditingData(rowData);
  // setIsCellEditing(true);
};
