import React, { useContext, useEffect, useRef, useState } from "react";
// API
import { onTableClick, triggerSingleAPI } from "api/teamRunApi";
// Hooks
import { prepareBody } from "hooks/useTrader";
import useWebSocket from "hooks/useWebSocket";

// Style
import "styles/index.css";
import "./TraderAgTables.scss";

import {
  addingBordersForCarbon,
  addingBordersForOptionsExceptCarbon,
  getDataFromLocalStorage,
  setDataToLocalStorage,
  updateTeamRunData,
} from "helpers/generalHelper";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";
import { TraderContext } from "contexts/TraderContext";
import useTrader from "hooks/useTrader";
import SingleTraderTable from "./SingleTraderTable";
import {
  TRADER_COULMN_STATE,
  TRADER_DRAGEABLE_STATE,
  TRADER_SELECTED_ROWS,
  TrADER_STATE,
} from "constants/trader";
import {
  setSelectedRowsForTrader,
  TRADER_DATAfun,
} from "helpers/traderHelper";

const TraderAgGridTables = (props) => {
  const gridRef = useRef({});
  const [columnState, setColumnState] = useState(
    TRADER_DATAfun()?.[TRADER_COULMN_STATE]
  );

  const location = useLocation();
  //!Call API To Fetch Tables Data
  useTrader();

  const {
    state: { teamRunData, productIds },
    setTeamrunRefAgGrid,
    setTeamRunData,
    setSelectedRows,
    
  } = useContext(TraderContext);
  
  const { data, title, product_id } = useWebSocket(teamRunData);
  const [checkSelection, setCheckSelection] = useState(false);
  // this is for dragable team run products in home page
  const [teamRunDataState, setTeamRunDataState] = useState(
    Object.keys(teamRunData || {})
  );

  const [isResizingColumn, setIsResizingColumn] = useState(false);

  useEffect(() => {
    let dataFromLocalStorage =
      getDataFromLocalStorage(TrADER_STATE)?.[TRADER_DRAGEABLE_STATE];
    if (dataFromLocalStorage) {
      setTeamRunDataState(dataFromLocalStorage);
    } else {
      setTeamRunDataState(Object.keys(teamRunData || {}));
    }
  }, [teamRunData]);

  useEffect(() => {
    // MouseOver event listener
    const handleMouseOver = (event) => {
      const isMouseOverHeader = event.target.closest(".ag-header-cell-resize");
      if (isMouseOverHeader) {
        setIsResizingColumn(true);
      } else {
        setIsResizingColumn(false);
      }
    };

    document.addEventListener("mouseover", handleMouseOver);
    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("mouseover", handleMouseOver);
    };
  });

 



  useEffect(() => {
    if (!gridRef.current) return;
    setTeamrunRefAgGrid(gridRef.current);
  }, [gridRef.current]);

  useEffect(() => {
    if (checkSelection) {
      setSelectedRowsForTrader(gridRef);
    }
  }, [checkSelection]);

  useEffect(() => {
    const onTeamRunProductSuccess = (data, productId, title) => {
      if (gridRef.current[title]) {
        let updatedData = updateTeamRunData(title, data, gridRef.current);
        setTeamRunData({ ...teamRunData, [title]: updatedData });
      } else {
        if (!productIds || !productId) return;
        const findProductName = productIds.find(
          (item) => item.id === productId
        )?.name;
        const prepareData = {
          ...teamRunData,
          [findProductName]: data,
        };
        setTeamRunData(prepareData);
      }
    };
    onTeamRunProductSuccess(data, product_id, title);
  }, [data, product_id, productIds, title, gridRef.current]);

  gridRef?.current.api?.refreshCells({ force: true });
  gridRef?.current.api?.redrawRows();

  const onGridReady = (params, productName) => {
    params.api.sizeColumnsToFit();
    // After all tables filled with data, rowSelection begin.....
    let TeamRunKeys = Object.keys(teamRunData || {});
    if (productName === TeamRunKeys[TeamRunKeys.length - 1]) {
      setCheckSelection(true);
    }

    if (columnState?.[productName]) {
      params.columnApi.applyColumnState({
        state: columnState[productName],
        applyOrder: true,
      });
    }

    ///###### FOR ADDING BORDERS BETWEEN DIFFERENT GROUPS OF ROWS AFTER SORTING IS APPLIED ###### --- commented out for now
    if (productName === "Carbon") {
      addingBordersForCarbon(params, productName);
    } else {
      addingBordersForOptionsExceptCarbon(params);
    }
  };

  const onColumnResized = (params, productName) => {
    let agGridColumnState = params.columnApi?.getColumnState();
    const columnsWidth = agGridColumnState.map((item) => ({
      width: item.width,
      colId: item.colId,
    }));
    let localStorageValue = TRADER_DATAfun();
    let columnState = localStorageValue?.[TRADER_COULMN_STATE];
    if (columnState) {
      columnState[productName] = columnsWidth;
    } else {
      columnState = { [productName]: columnsWidth };
    }
    let newData = {
      ...localStorageValue,
      [TRADER_COULMN_STATE]: columnState,
    };
    setDataToLocalStorage(TrADER_STATE, newData);
    setColumnState(columnState);
  };

  const onTableClickHandler = (productName, classNameClicked) => {
    if (classNameClicked !== "ag-center-cols-viewport") return;
    handleNotifyTeamRunRowsViewed(productName);
  };
  function triggerSingleApiFun(findProductId) {
    triggerSingleAPI(findProductId)
      .then(({ data }) => {
        const formattedData = prepareBody(data);
        setTeamRunData(formattedData);
      })
      .catch((err) => console.log({ err }));
  }

  /// to handle saving that the rows are viewed
  const handleNotifyTeamRunRowsViewed = (productName) => {
    if (!productIds || !productName) return;
    const findProductId = productIds?.find(
      (productItem) => productItem.name === productName
    )?.id;
    const isTeamRunIsNewPropertyTrue = teamRunData[productName]?.some(
      (item) => !!item.is_new
    );
    if (!isTeamRunIsNewPropertyTrue) return;

    onTableClick(findProductId).then(() => {
      triggerSingleApiFun(findProductId);
    });
  };

  const handleRowsSelection = (params, productName) => {
    const selectedRows = params.api.getSelectedRows();
    let localStorageValue = TRADER_DATAfun();
    let rows = {};
    if (localStorageValue?.[TRADER_SELECTED_ROWS]) {
      rows = localStorageValue[TRADER_SELECTED_ROWS];
    }
    rows[productName] = selectedRows.map((row) => row.summary_id);
    let newData = { ...localStorageValue, [TRADER_SELECTED_ROWS]: rows };
    setDataToLocalStorage(TrADER_STATE, newData);
    setSelectedRows(rows);
  };

  const handleDragAndDrop = (results) => {
    const { source, destination, type } = results;
    if (!destination) return;
    if (
      source.droppableId === destination.draggableId &&
      source.index === destination.index
    )
      return;

    // changing the order of array according the the latest position.
    if (type === "group") {
      const reorderedStores = teamRunDataState;
      const sourceIndex = source.index;
      const destinationIndex = destination.index;
      const [removedStore] = reorderedStores.splice(sourceIndex, 1);
      reorderedStores.splice(destinationIndex, 0, removedStore);
      const teamRunLocalStorageData = getDataFromLocalStorage(TrADER_STATE);
      let newDataForLocalStorage = {
        ...teamRunLocalStorageData,
        [TRADER_DRAGEABLE_STATE]: reorderedStores,
      };
      setDataToLocalStorage(TrADER_STATE, newDataForLocalStorage);
      return setTeamRunDataState(reorderedStores);
    }
  };

  const teamRunProducts = () => {
    if (location.pathname.includes("team-run")) {
      return Object.keys(teamRunData || {});
    } else {
      return teamRunDataState;
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragAndDrop}>
      <Droppable
        droppableId="root-trader"
        type="group"
        direction="horizontal"
      >
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="dropable-container"
          >
            <div className="ag-grid-dynamic-table__container">
              {[...teamRunProducts()].map((item, index) => {
                return (
                  <Draggable
                    draggableId={item}
                    key={item}
                    index={index}
                    isDragDisabled={
                      location.pathname.includes("team-run") || isResizingColumn
                    } // Dragable is disabled for team-run page
                  >
                    {(provided) => (
                      <div
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        className="table-box"
                        onClick={(event) =>
                          onTableClickHandler(item, event.target.className)
                        }
                      >
                        <SingleTraderTable
                          gridRef={gridRef}
                          handleRowsSelection={handleRowsSelection}
                          item={item}
                          teamRunData={teamRunData}
                          key={item}
                          onColumnResized={onColumnResized}
                          onGridReady={onGridReady}
                          productIds={productIds}
                          handleNotifyTeamRunRowsViewed={
                            handleNotifyTeamRunRowsViewed
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}

              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>

     
    </DragDropContext>
  );
};

export default React.memo(TraderAgGridTables);
