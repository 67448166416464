import { createContext, useContext, useReducer, useCallback } from "react";
import { VolSurfaceContext } from "./VolSurfaceContext";

export const initialState = {
  volSurfaceTablesData: null,
  isLoading: false,
  volSurfaceRefAgGrid: null,
  isCellEditing: false,
  rowEditingData: null,
  volSurfaceGraphData: null,
  volCalcTable: null,
  tableHeight: 0,
  setTableHeight: null,
  scrollPosition: 0,
  risk_free: 0,
  surfaceId: null,
  surfaceTime: null,
  selectedRows: [],
  stickyStrikeData: {
    table: [],
    time: null,
    calculation_date: null,
    risk_free: null,
  },
  isStrikeCellEditing: false,
};

const SET_IS_VOL_SURFACE_FETCHING = "SET_IS_VOL_SURFACE_FETCHING";
const SET_IS_VOL_SURFACE_FINISHED_FETCHING =
  "SET_IS_VOL_SURFACE_FINISHED_FETCHING";
const SET_VOL_SURFACE_AG_GRID_REF = "SET_VOL_SURFACE_AG_GRID_REF";
const SET_VOL_SURFACE_DATA = "SET_VOL_SURFACE_DATA";
const SET_FILTER_BUTTON_SELECTED = "SET_FILTER_BUTTON_SELECTED";
const SET_ROW_EDITING_DATA = "SET_ROW_EDITING_DATA";
const SET_IS_CELL_EDITING = "SET_IS_CELL_EDITING";
const SET_VolSurfaceGraphData = "SET_VolSurfaceGraphData";
const SET_VOL_CALC_TABLE = "SET_VOL_CALC_TABLE";
const SET_TABLE_HEIGHT = "SET_TABLE_HEIGHT";
const SET_SCROLL_POSITION = "SET_SCROLL_POSITION";
const SET_RISK_FREE = "SET_RISK_FREE";
const SET_SURFACE_ID = "SET_SURFACE_ID";
const SET_SURFACE_TIME = "SET_SURFACE_TIME";
const SET_SELECTED_ROWS = "SET_SELECTED_ROWS";
const SET_STICKY_STRIKE_DATA = "SET_STICKY_STRIKE_DATA";
const SET_IS_STRIKE_CELL_EDITING = "SET_IS_STRIKE_CELL_EDITING";



const reducer = (state, action) => {
  switch (action.type) {
    case SET_TABLE_HEIGHT:
      return {
        ...state,
        tableHeight: action.payload,
      };

    case SET_STICKY_STRIKE_DATA:
      return {
        ...state,
        stickyStrikeData: action.payload,
      };
    case SET_IS_STRIKE_CELL_EDITING:
      return {
        ...state,
        isStrikeCellEditing: action.payload,
      };

    case SET_SURFACE_ID:
      return {
        ...state,
        surfaceId: action.payload,
      };

    case SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.payload,
      };

    case SET_SURFACE_TIME:
      return {
        ...state,
        surfaceTime: action.payload,
      };

    case SET_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.payload,
      };
    case SET_IS_VOL_SURFACE_FETCHING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_VOL_CALC_TABLE:
      return {
        ...state,
        volCalcTable: action.payload,
      };

    case SET_IS_VOL_SURFACE_FINISHED_FETCHING:
      return {
        ...state,
        isLoading: false,
      };
    case SET_FILTER_BUTTON_SELECTED:
      return {
        ...state,
        filterButtonSelected: action.payload,
      };
    case SET_VOL_SURFACE_AG_GRID_REF:
      return {
        ...state,
        pricerRefAgGrid: action.payload,
      };

    case SET_RISK_FREE:
      return {
        ...state,
        risk_free: action.payload,
      };

    case SET_VOL_SURFACE_DATA:
      return {
        ...state,
        volSurfaceTablesData: action.payload,
        isLoading: false,
      };

    case SET_IS_CELL_EDITING:
      return {
        ...state,
        isCellEditing: action.payload,
      };
    case SET_VolSurfaceGraphData:
      return {
        ...state,
        volSurfaceGraphData: action.payload,
      };

    case SET_ROW_EDITING_DATA:
      return {
        ...state,
        rowEditingData: action.payload,
      };
    default:
      return state;
  }
};
const VolSurfaceControlTableContext = createContext();

function VolSurfaceControlTableProvider({
  children,
  defaultInitialState = {},
}) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...defaultInitialState,
  });

  const setStickyStrikeData = useCallback((value) => {
    dispatch({ type: SET_STICKY_STRIKE_DATA, payload: value });
  }, []);



  const setIsStrikeCellEditing = useCallback((value) => {
    dispatch({ type: SET_IS_STRIKE_CELL_EDITING, payload: value });
  }, []);

  const setSelectedRows = useCallback((value) => {
    dispatch({ type: SET_SELECTED_ROWS, payload: value });
  }, []);

  const setSurfaceId = useCallback((value) => {
    dispatch({ type: SET_SURFACE_ID, payload: value });
  }, []);

  const setSurfaceTime = useCallback((value) => {
    dispatch({ type: SET_SURFACE_TIME, payload: value });
  }, []);

  const setVolSurfaceData = useCallback((value) => {
    dispatch({ type: SET_VOL_SURFACE_DATA, payload: value });
  }, []);

  const setIsCellEditing = useCallback((value) => {
    dispatch({ type: SET_IS_CELL_EDITING, payload: value });
  }, []);

  const setVolCalcTable = useCallback((value) => {
    dispatch({ type: SET_VOL_CALC_TABLE, payload: value });
  }, []);

  const setRowEditingData = useCallback((value) => {
    dispatch({ type: SET_ROW_EDITING_DATA, payload: value });
  }, []);

  const setScrollPosition = useCallback((value) => {
    dispatch({ type: SET_SCROLL_POSITION, payload: value });
  }, []);

  const setRiskFree = useCallback((value) => {
    dispatch({ type: SET_RISK_FREE, payload: value });
  }, []);

  const setVolSurfaceGraphData = useCallback((value) => {
    dispatch({ type: SET_VolSurfaceGraphData, payload: value });
  }, []);

  const setTableHeight = useCallback((value) => {
    dispatch({ type: SET_TABLE_HEIGHT, payload: value });
  }, []);

 

  const setIsOptionRowLoading = useCallback((value) => {
    if (value) {
      dispatch({ type: SET_IS_VOL_SURFACE_FETCHING, payload: value });
      return;
    }
    dispatch({ type: SET_IS_VOL_SURFACE_FINISHED_FETCHING, payload: value });
  }, []);

  const setVolSurfaceAgGridRef = useCallback((value) => {
    dispatch({ type: SET_VOL_SURFACE_AG_GRID_REF, payload: value });
  }, []);

  const {
    state: { selectedFilters },
  } = useContext(VolSurfaceContext);

  return (
    <VolSurfaceControlTableContext.Provider
      value={{
        ...state,
        setRiskFree,
        setVolSurfaceData,
        setIsOptionRowLoading,
        setVolSurfaceAgGridRef,
        setIsCellEditing,
        setRowEditingData,
        setVolSurfaceGraphData,
        setVolCalcTable,
        setTableHeight,
        setScrollPosition,
        setSurfaceId,
        selectedFilters,
        setSurfaceTime,
        setSelectedRows,
        setStickyStrikeData,
        setIsStrikeCellEditing,
      }}
    >
      {children}
    </VolSurfaceControlTableContext.Provider>
  );
}

function useVolSurfaceControlTable() {
  const context = useContext(VolSurfaceControlTableContext);
  if (context === undefined) {
    throw new Error(
      "useVolSurfaceControlTable must be used within a VolSurfaceControlTableProvider"
    );
  }
  return context;
}

export { VolSurfaceControlTableProvider, useVolSurfaceControlTable };
