import { getSurfacesData, getVolSurfaceData } from "api/volSurfaceApi";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { useEffect, useRef, useState } from "react";

function useVolSurfaceData() {
  const [gridHeight, setGridHeight] = useState("auto");
  const [volSurfaceData, setVolSurfaceData] = useState({});
  const [surfaces, setSurfaces] = useState([]);
  const [surface, setSurface] = useState(null);
  const gridRef = useRef();


  const {
    setVolSurfaceData: setRowsData,
    isLoading,
    selectedFilters,
    setSurfaceId,
    setTableHeight,
  } = useVolSurfaceControlTable();



  //get surfaces
  useEffect(() => {
    if (!selectedFilters?.product_id) return;
    getSurfacesData({
      product_contract_style_id: selectedFilters?.product_id,
    }).then((res) => {
      setSurfaces(res.data.data);
      setSurface(res.data.data.at(0).value);
    });
  }, [selectedFilters?.product_id]);

  //Get Control Table Data
  useEffect(() => {
    if (!surface || !selectedFilters?.product_id) return;
    getVolSurfaceData({
      product_contract_style_id: selectedFilters?.product_id,
      surface_id: surface,
    }).then((res) => {
      setVolSurfaceData(res.data.data);
      setRowsData(res.data.data.main_control_table);
    });
  }, [
    selectedFilters?.product_id,
    selectedFilters?.shortcutFilter,
    setRowsData,
    surface,
  ]);

  useEffect(() => {
    setSurfaceId(surface);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surface]);

  //handle grid height
  useEffect(() => {
    if (volSurfaceData.main_control_table?.length === 0) return;
    const numberOfRowData = volSurfaceData.main_control_table?.length;
    const calculatedHeight = numberOfRowData * 30 + 95;
    setGridHeight(`${calculatedHeight}px`);
    setTableHeight(`${calculatedHeight}px`);
  }, [volSurfaceData.main_control_table?.length, setTableHeight]);


    function onSurfaceChange(value) {
      setSurface(value);
      setSurfaceId(value.value);
    }


  return {
    gridRef,
    gridHeight,
    volSurfaceData,
    surfaces,
    surface,
    isLoading,
    setSurface,
    selectedFilters,
    onSurfaceChange
  };
}

export default useVolSurfaceData
