import {
  ACTIVE_FONT_COLOR,
  INACTIVE_FONT_COLOR,
  TEAM_RUN_COULMN_STATE,
  TEAM_RUN_DRAGEABLE_STATE,
  TEAM_RUN_SELECTED_ROWS,
  TEAMRUN_STATE,
} from "constants/teamRun";
import _ from "lodash";
import {
  addingBordersForCarbon,
  addingBordersForOptionsExceptCarbon,
  getDataFromLocalStorage,
  getSvgPath_d,
  setDataToLocalStorage,
} from "./generalHelper";

export const TEAMRUN_DATAfun = () => getDataFromLocalStorage(TEAMRUN_STATE);

export const setSelectedRowsForTeamRun = (gridRef) => {
  const teamRunDataFromLocalStorage = TEAMRUN_DATAfun();
  if (
    _.isEmpty(teamRunDataFromLocalStorage) ||
    !teamRunDataFromLocalStorage[TEAM_RUN_SELECTED_ROWS]
  )
    return;
  if (
    Object.keys(teamRunDataFromLocalStorage[TEAM_RUN_SELECTED_ROWS]).length > 0
  ) {
    Object.keys(teamRunDataFromLocalStorage[TEAM_RUN_SELECTED_ROWS]).forEach(
      (product) => {
        if (
          teamRunDataFromLocalStorage[TEAM_RUN_SELECTED_ROWS][product].length >
          0
        ) {
          teamRunDataFromLocalStorage[TEAM_RUN_SELECTED_ROWS][product].forEach(
            (summaryId) => {
              gridRef?.current[product]?.api &&
                gridRef.current[product]?.api?.forEachNode((node) => {
                  if (node.data.summary_id === summaryId) {
                    node.setSelected(true);
                  }
                });
            }
          );
        }
      }
    );
  }
};

export const onTeamTablesReady = (
  params,
  productName,
  columnState,
  teamRunData,
  setCheckSelection
) => {
  params.api.sizeColumnsToFit();
  // After all tables filled with data, rowSelection begin.....
  let TeamRunKeys = Object.keys(teamRunData);
  if (productName === TeamRunKeys[TeamRunKeys.length - 1]) {
    setCheckSelection(true);
  }

  if (columnState?.[productName]) {
    params.columnApi.applyColumnState({
      state: columnState[productName],
      applyOrder: true,
    });
  }

  ///###### FOR ADDING BORDERS BETWEEN DIFFERENT GROUPS OF ROWS AFTER SORTING IS APPLIED ###### --- commented out for now
  if (productName === "Carbon") {
    addingBordersForCarbon(params, productName);
  } else {
    addingBordersForOptionsExceptCarbon(params);
  }
};

export const onTeamRunColumnResized = (params, productName, setColumnState) => {
  let agGridColumnState = params.columnApi?.getColumnState();
  const columnsWidth = agGridColumnState.map((item) => ({
    width: item.width,
    colId: item.colId,
  }));
  let localStorageValue = TEAMRUN_DATAfun();
  let columnState = localStorageValue?.[TEAM_RUN_COULMN_STATE];
  if (columnState) {
    columnState[productName] = columnsWidth;
  } else {
    columnState = { [productName]: columnsWidth };
  }
  let newData = {
    ...localStorageValue,
    [TEAM_RUN_COULMN_STATE]: columnState,
  };
  setDataToLocalStorage(TEAMRUN_STATE, newData);
  setColumnState(columnState);
};

export const onTeamRunRowsSelection = (
  params,
  productName,
  setSelectedRows
) => {
  const selectedRows = params.api.getSelectedRows();
  let localStorageValue = TEAMRUN_DATAfun();
  let rows = {};
  if (localStorageValue?.[TEAM_RUN_SELECTED_ROWS]) {
    rows = localStorageValue[TEAM_RUN_SELECTED_ROWS];
  }
  rows[productName] = selectedRows.map((row) => row.summary_id);
  let newData = { ...localStorageValue, [TEAM_RUN_SELECTED_ROWS]: rows };
  setDataToLocalStorage(TEAMRUN_STATE, newData);
  setSelectedRows(rows);
};

export const onTeamRunDragAndDrop = (
  results,
  teamRunDataState,
  setTeamRunDataState
) => {
  const { source, destination, type } = results;
  if (!destination) return;
  if (
    source.droppableId === destination.draggableId &&
    source.index === destination.index
  )
    return;

  // changing the order of array according the the latest position.
  if (type === "group") {
    const reorderedStores = teamRunDataState;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    const [removedStore] = reorderedStores.splice(sourceIndex, 1);
    reorderedStores.splice(destinationIndex, 0, removedStore);
    const teamRunLocalStorageData = getDataFromLocalStorage(TEAMRUN_STATE);
    let newDataForLocalStorage = {
      ...teamRunLocalStorageData,
      [TEAM_RUN_DRAGEABLE_STATE]: reorderedStores,
    };
    setDataToLocalStorage(TEAMRUN_STATE, newDataForLocalStorage);
    return setTeamRunDataState(reorderedStores);
  }
};

export const onTeamRunCellClickListener = (
  e,
  productName,
  handleNotifyTeamRunRowsViewed
) => {
  const field = e.colDef.field;
  // ------- handling notify team run rows viewed -------
  const checkMenu = getSvgPath_d(e);

  const itemObject = e.event.target.dataset;

  // const item =  itemObjec
  if (field === "title") {
    handleNotifyTeamRunRowsViewed(productName);
  }
  // ------- handling notify team run rows viewed -------
  const item =
    Object.keys(itemObject).length > 0
      ? itemObject.icon
      : checkMenu
      ? "menu"
      : undefined;
  const isbulkMenuPressed = field === "actions" && item === "menu";
  const isSelected = e.node.isSelected();
  //deselects all nodes.
  const deselectAll = () => e?.api?.deselectAll();
  if (e.node.level > 0) {
    deselectAll();
    const parent = e.node.parent;
    let children = parent.allLeafChildren;
    if (parent !== "undefined" && parent.group && parent.level !== 0)
      children = parent.parent.allLeafChildren;
    children?.forEach((node) => node.setSelected(true));
    return;
  }
  /*
      this is for summary rows.
      if pressed on action menu just add the row to selected
      if not action menu remove selection on other rows and set it selected
    */
  if (!(isbulkMenuPressed && isSelected)) deselectAll();
  e.node.setSelected(true);
};


  // Function to determine font color
 export const determineFontColorForTeamRun = (data, darkTheme) => {
    if (darkTheme) {
      return data.font_color;
    }
    return data.is_active ? ACTIVE_FONT_COLOR : INACTIVE_FONT_COLOR;
  };