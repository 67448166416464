import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";

function AddColButton({
  isTradeRequestFire,
  setTradeData,
  isRequestsActive
}) {
  return (
    <Button
      disabled={isTradeRequestFire === null||isRequestsActive}
      style={{
        width: "40px",
        height: "30px",
        marginLeft: "20px",
      }}
      className="d-flex justify-content-center align-items-center border-1 border p-1"
      onClick={() => {
        setTradeData((prev) => {
          return {
            data: {
              ...prev.data,
              trades: [
                ...prev.data.trades,
                {
                  ...prev.data.trades.at(0),
                  column_id: new Date().getTime(),
                  legs: prev.data.trades.at(0).legs.map((leg, index) => {
                    return {
                      ...leg,
                      leg_col: index + 1,
                    };
                  }),
                  hedges: prev.data.trades.at(0).hedges.map((hedge, index) => {
                    return {
                      ...hedge,
                      hedge_col: index + 1,
                      col_index: index,
                    };
                  }),
                },
              ],
            },
          };
        });
      }}
    >
      <PlusOutlined />
    </Button>
  );
}

export default AddColButton
