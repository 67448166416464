import { MinusOutlined } from "@ant-design/icons";
import { Button } from "antd";

function RemoveColButton({
  col,
  setTradeData,
  isTradeRequestFire,
  isRequestsActive,
}) {
  return (
    <Button
      disabled={isTradeRequestFire === null || isRequestsActive}
      style={{
        width: "40px",
        height: "30px",
        marginLeft: "20px",
      }}
      className="d-flex justify-content-center align-items-center border-1 border p-1"
      onClick={() => {
        setTradeData((prev) => {
          return {
            data: {
              ...prev.data,
              trades: [
                ...prev.data.trades.filter(
                  (el) => el.column_id !== col.column_id
                ),
              ],
            },
          };
        });
      }}
    >
      <MinusOutlined />
    </Button>
  );
}

export default RemoveColButton
