import { Col, InputNumber, Row } from "antd";

function EditTradePremium({
  tradedPremium,
  editTradeValue,
  setEditTradeValue,
  setIsRequestActive,
  isTradeRequestFire,
  disabled
}) {
  return (
    <Col span={24}>
      <Row>
        <Col span={12}>
          <Row justify={"start"}>
            <Col span={12}>
              <p>{tradedPremium?.label}</p>
            </Col>
            <Col>
              <InputNumber
                disabled={isTradeRequestFire === null || disabled}
                type="number"
                onStep={() => setIsRequestActive(true)}
                onInput={() => setIsRequestActive(true)}
                onFocus={() => setIsRequestActive(true)}
                onBlur={() => setIsRequestActive(false)}
                value={
                  editTradeValue !== undefined
                    ? editTradeValue
                    : tradedPremium?.premium.value
                }
                onChange={(value) => setEditTradeValue(value)}
                addonAfter={tradedPremium?.premium.symbol}
                step={tradedPremium?.premium.step_size || 0.005}
                style={{ marginLeft: "auto" }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={12}></Col>
      </Row>
    </Col>
  );
}

export default EditTradePremium;
