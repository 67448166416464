import { getDataFromLocalStorage } from "helpers/generalHelper";
import { PRICER_NODES_COLORS } from "./pricerTable";

export const rowHeight = () => 27;
export const headerHeight = 32;

//! Please Note That Common Style Not Invoked In Pricer Since It Effects In Selected Rows
export const commonStyle =  {
  backgroundColor : 'var(--color-black-1)'
};

export const LightThemeStyle =  {
  fontSize        : 'var(--font-size-10)',
  backgroundColor : 'transparent'
};



export const isDisable = (params,pricerTablesDataWithRowDisable) => {
  const summaryId = params.data && params.data.summary_id? params.data.summary_id:params.node.aggData?.summary_id;
  const isDisable = pricerTablesDataWithRowDisable?.find(data => data.summary_id === summaryId)?.isDisable;
  return isDisable
};



const isOptionExpired = (params) => {
  const rowData = params.node.group ? params.node.aggData : params.node.data;
  const isExpired = rowData?.is_expired;
  return isExpired === 1;
}

export const getRowClass = (params) => {
  const nodeData = params.node.group ? params.node.aggData : params.node.data;
  const rowType = nodeData.row_type;
  const level = params.node.level;
  // if level 0 then row is summar-row
  // if level 1 and row_type is 2 then row is summar-row
  // if level 2 then row is subleg-row
  if(level === 0) {
    return "summary-row";
  }
  if((level === 1 && rowType === 2) || level === 2) {
    return "subleg-row";
  }
  if(level === 1) {
    return "leg-row";
  }
  // this is for making debugging easier
  return  "getRowClass: no-specified-class";
}


export const getRowStyle = (params,pricerTablesDataWithRowDisable)=>{
  const disableRow=isDisable(params, pricerTablesDataWithRowDisable);
  if(isOptionExpired(params)) {
    return { color: 'var(--color-red-3)' };
  }
  if(disableRow){
    return {color:'var(--color-gray-2)', pointerEvents: 'none'}
  }
  const nodesColorsArr =   getDataFromLocalStorage(PRICER_NODES_COLORS);

  if (nodesColorsArr) {
    const nodesArray = nodesColorsArr.map((item) => {
      let selectedColorObject = null;
      COLORS.map(obj => {
        if(obj.name === item.row_color) {
          selectedColorObject = obj;
          return obj;
        }
      })
      return Object.assign({}, item, selectedColorObject);
    })
    const rowData = params.node.group ? params.node.aggData : params.node.data;
    const colorStyle = nodesArray.filter((i) => i.summary_id === Number(rowData?.summary_id)).map((i) => i.color)
    return { color: colorStyle };
  }
}


export const COLORS = [
  { key:"1", name:'Green',        color:'var(--color-green-2)' },
  { key:"2", name:'Yellow',       color:'var(--color-yellow-2)' },
  { key:"3", name:'Magenta',      color:'var(--color-magenta-1)' },
  { key:"3", name:'remove_color', color:'' }
]