import { Col, DatePicker,Row } from "antd";
import moment from "moment";
import { useLocation } from "react-router-dom";
const { RangePicker } = DatePicker;

export default function SelectDateRange({
  dark_Theme,
  start_date,
  end_date,
  setStart_date,
  setEnd_date,
}) {
  
  const location = useLocation();
  const pathName = location.pathname;
  const volSurfaceDataPath = pathName.includes("vol-surface-data");
  const isVolSurfacePage = pathName.includes("vol-surface");

  const handleRangePickerChange = (value) => {
    if (volSurfaceDataPath) return;
    if (value) {
      const [start, end] = value;
      setStart_date(start.format("YYYY-MM-DD"));
      setEnd_date(end.format("YYYY-MM-DD"));
    } else {
      setStart_date("");
      setEnd_date("");
    }
  };

  return (
    <>
      <Col span={24}>
        { (
          <Row
            size={"middle"}
            className={`${dark_Theme && "date-picker-dark"}`}
          >
            <Col span={3}>
              <span style={{ whiteSpace: "nowrap" }}>Data to include:</span>
            </Col>
            <Col>
              <RangePicker
                onChange={handleRangePickerChange}
                className={start_date && "filled--data_rangePicker__background"}
                popupClassName={dark_Theme && "date-picker-dropdown-dark"}
                value={start_date ? [moment(start_date), moment(end_date)] : ""}
              />
            </Col>
          </Row>
        )}
      </Col>
    </>
  );
}
