import { useContext, useEffect } from "react";
import { Button, Col, Space } from "antd";
import classNames from "classnames";
import { Sticky_Delta_Strike_LABELS } from "constants/generalActionButtons";
import { VOL_SURFACE_STICKY_DELTA_OR_STRIKE } from "constants/volSurface";
import { GlobalContext } from "contexts/GlobalContext";
import {
  getDataFromLocalStorage,
  setDataToLocalStorage,
} from "helpers/generalHelper";
import { VolSurfaceContext } from "contexts/VolSurfaceContext";

function StickyDeltaOrStrickTabs({ currentTab, setCurrentTab }) {
  
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const { setVolSurfaceFilterShortcuts } = useContext(VolSurfaceContext);

  useEffect(() => {
    let volSurfaceBtn = getDataFromLocalStorage(
      VOL_SURFACE_STICKY_DELTA_OR_STRIKE
    );
    if (!volSurfaceBtn) {
      setCurrentTab(Sticky_Delta_Strike_LABELS.STICKY_Delta);
    } else {
      setCurrentTab(volSurfaceBtn);
    }
  }, [setCurrentTab]);

  function onClickHandle(item) {
    if (item === currentTab) return;
    setVolSurfaceFilterShortcuts(null);
    setCurrentTab(item);
    setDataToLocalStorage(VOL_SURFACE_STICKY_DELTA_OR_STRIKE, item);
  }

  return (
    <Col span={24}>
      <Space>
        {Object.keys(Sticky_Delta_Strike_LABELS).map((item, index) => {
          return (
            <Button
            style={{ marginBottom: "10px" }}
              className={classNames({
                "ant-btn-dark": dark_Theme,
                "selected-button":
                  Sticky_Delta_Strike_LABELS[item] === currentTab,
              })}
              key={Sticky_Delta_Strike_LABELS[item] + index}
              onClick={() => onClickHandle(Sticky_Delta_Strike_LABELS[item])}
            >
              {Sticky_Delta_Strike_LABELS[item]}
            </Button>
          );
        })}
      </Space>
    </Col>
  );
}

export default StickyDeltaOrStrickTabs;
