/* eslint-disable react-hooks/exhaustive-deps */
import { InputNumber } from "antd";
import { debounce } from "lodash";
import { updateTradeDeltaQuantity } from "api/teamRunApi";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { API_KEYS, BACKEND_KEYS_NAME } from "constants/editTradeDetails";
import { toast } from "helpers/generalHelper";

const CustomTradeInputForDelta = ({
  placeholderTitle,
  hedge,
  isDelta,
  isSellActive,
  handleHedgeQuantityChange,
  setIsRequestActive,
  temp_summary_id,
  isDisabled,
  colId,
  setIsDeltaRequestActive,
  oldTradeData,
}) => {
  let keyName = isDelta
    ? BACKEND_KEYS_NAME.DELTA
    : BACKEND_KEYS_NAME.DELTA_QUANTITY_T;
  const [inputvalue, setInputValue] = useState(
    hedge[keyName]?.[BACKEND_KEYS_NAME.VALUE]
  );
  const valueTrackRef = useRef(hedge[keyName]?.[BACKEND_KEYS_NAME.VALUE]);

  useEffect(() => {
    setInputValue(hedge[keyName]?.[BACKEND_KEYS_NAME.VALUE]);
  }, [hedge[keyName]?.[BACKEND_KEYS_NAME.VALUE]]);

  function updateDelta(value) {
    if (value < 0) {
      return;
    }
    let bodyParams = {
      temp_summary_id: temp_summary_id,
      is_sell: isSellActive ? 1 : 0,
      id: hedge.id,
      hedge_sell_or_buy: hedge?.sell_or_buy,
      col_id: colId,
    };

    if (isDelta) {
      bodyParams.delta = value;
    } else {
      bodyParams.delta_quantity = value;
    }
    setIsDeltaRequestActive(true);
    updateTradeDeltaQuantity(bodyParams)
      .then((res) => {
        if (res.status === 200) {
          handleHedgeQuantityChange(
            hedge.hedge_col,
            res.data.data,
            hedge.col_index
          );
        } else {
          setInputValue(valueTrackRef.current);
        }
      })
      .catch((err) => {
        handleHedgeQuantityChange(
          hedge.hedge_col,
          {
            id: hedge.id,
            delta_quantity: oldTradeData.delta_quantity_t,
            delta: oldTradeData.delta,
          },
          hedge.col_index,
          false
        );
        console.log("Error while updating Delta Quantity", err);
      })
      .finally(() => {
        setIsRequestActive(false);
        setIsDeltaRequestActive(false);
      });
  }

  const handleChangeValueDebounce = useCallback(
    debounce((value) => updateDelta(value), 500),
    [isSellActive]
  );

  const onChangeDeltaValue = (value) => {
    if (value < 0) {
      toast(`Negative not accepted`, "error");
    } else {
      setInputValue(value);
      setIsRequestActive(true);
      handleChangeValueDebounce(value);
    }
  };

  const inputStyle = {
    overflow: "hidden",
    textOverflow: "clip", // Or 'clip' if you don't want ellipsis
    whiteSpace: "nowrap",
  };

  return (
    <InputNumber
      style={inputStyle}
      placeholder={placeholderTitle}
      disabled={
        isDisabled ||
        hedge[BACKEND_KEYS_NAME.DELTA_QUANTITY_T]?.disabled ||
        false
      }
      value={inputvalue}
      step={
        hedge[BACKEND_KEYS_NAME.DELTA_QUANTITY_T][
          BACKEND_KEYS_NAME.STEP_SIZE
        ] || 1
      }
      onChange={onChangeDeltaValue}
      addonAfter={hedge[keyName][BACKEND_KEYS_NAME.SYMBOL]}
    />
  );
};

export default CustomTradeInputForDelta;
