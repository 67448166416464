import { Col } from "antd";
import {
  BUTTON_NAMES,
  FIELDS_LABEL,
  INPUT_KEYS,
  IS_BUY,
  RADIO_GROUP_BUTTONS,
} from "constants/addOption";
import { FormItem, InputNumberWithRadioButton } from "./AddOptionHelperComponents";

function Ratio({
  fetchedData,
  isCalcRatioVisible,
  ratioValues,
  setRatioValues,
}) {
  const onRatioChange = (key, value, index) => {
    const data =
      fetchedData[FIELDS_LABEL.CONSTRAINTS.key] &&
      fetchedData[FIELDS_LABEL.CONSTRAINTS.key][FIELDS_LABEL.RATIO.key][index][
        IS_BUY
      ];

    let buttonVal;

    if (data === null) buttonVal = null;
    if (data === 1) buttonVal = BUTTON_NAMES.BUY;
    if (data === 0) buttonVal = BUTTON_NAMES.SELL;

    if (key !== INPUT_KEYS.BUTTON) {
      setRatioValues({
        ...ratioValues,
        [index]: {
          [INPUT_KEYS.BUTTON]: buttonVal,
          [IS_BUY]: data,
          ...ratioValues[index],
          [key]: value,
        },
      });
      return;
    }

    setRatioValues({
      ...ratioValues,
      [index]: { ...ratioValues[index], [key]: value },
    });
  };

  return (
    <Col span={24}>
      <FormItem label={FIELDS_LABEL.RATIO.label}>
        <div className="horizontal-scroll__container">
          {/* <div className="ratio-and-strike__container"> */}
          {fetchedData[FIELDS_LABEL.CONSTRAINTS.key]?.[
            FIELDS_LABEL.RATIO.key
          ].map((item, index) => {
            return (
              <InputNumberWithRadioButton
                key={index}
                isDisabled={
                  item.value !== null ||
                  isCalcRatioVisible[RADIO_GROUP_BUTTONS.Q_BUTTON] ===
                    RADIO_GROUP_BUTTONS.Q
                    ? true
                    : false
                }
                label={FIELDS_LABEL.RATIO.label}
                value={
                  item.value === null && ratioValues
                    ? ratioValues?.[index]?.[INPUT_KEYS.VALUE]
                    : item.value
                }
                onChange={(key, val) => onRatioChange(key, val, index)}
                data={item}
              />
            );
          })}
          {/* </div> */}
        </div>
      </FormItem>
    </Col>
  );
}

export default Ratio;
