import {
  UNEDITABLE_FIELDS_BASED_ON_MORE_THAN_TWO_LEGS,
  FIELD_NAMES,
  COLUMN_CELL_TO_HAVE_RIGHT_BORDER,
  EDITABLE_FIELDS_BASED_ON_LEVEL,
  ALWAYS_POSITIVE_PARAMS,
  ALWAYS_POSITIVE_LEG_AND_SUB_LEG_PARAMS,
  HIDE_VALUES_IN_SUBLEGS,
} from "constants/pricerTable";
import { toast } from "helpers/generalHelper";
import { formatUnit } from "./helperFunc";
import {
  reverseAggregateRf,
  premiumOrVolatilityLegLevelreverseAggregate,
  reverseAggregateSummaryRowVolatility,
  reverseAggregateLegRowStrikeOrUnderlying,
  reverseAggregateSummaryRowQuantity,
  reverseAggregateSummaryRowPremium,
} from "./reverseAggregates";
import {
  defaultcomparator,
  strikeComparator,
  tenorComparator,
  typeComparator,
  summaryIdComparator,
} from "helpers/Pricer/helperSortingFunc";
import { formatNumber, isSummaryLevel } from "../generalHelper";
import { roundAndFormat } from "./helperAggFunc";

//? Cell Styles Functions
export const allCellsStyle = {
  cellStyle: () => ({
    display: "flex",
    alignItems: "center",
  }),
};

export const rowLevelStyle = () => {
  return {
    "level-color__0": (params) =>
      isSummaryLevel(params) || isSummaryLegLevel(params),
    "level-color__1": (params) => isLegLevel(params),
    "level-color__2": (params) => isSubLegLevel(params),
  };
};

//Determines if the current node is at the leg level based on the parameters.
export const isLegLevel = (params) => {
  const rowData = params.node.group ? params.node.aggData : params.node.data;
  const level = params.node.level;
  return rowData.row_type === 1 && level !== 0;
};

//Determines if the current node is at the summary leg level based on the parameters.
export const isSummaryLegLevel = (params) => {
  const rowData = params.node.group ? params.node.aggData : params.node.data;
  const level = params.node.level;
  return rowData.row_type === 1 && level === 0;
};

//Checks if the given node has three or more legs.
export const hasThreeOrMoreLegs = (params) => {
  if (!params.node.group) return;
  const hasThreeOrMoreLegs = params.node?.childrenAfterAggFilter.length > 2;
  return hasThreeOrMoreLegs;
};

//Determines if the current node is at the sub leg level based on the parameters.
const isSubLegLevel = (params) => {
  const rowData = params.node.group ? params.node.aggData : params.node.data;
  return rowData.row_type === 2;
};

/**
 * Determines the editability of a cell based on specific rules and conditions.
 * @param {Object} event - The event object containing information about the event.
 * @param {boolean} editable - A boolean indicating whether the cell is editable.
 * @return {boolean} Indicates whether the cell is editable based on the rules.
 */
export function editableColumnsFunc(event, editable, rowEditingData) {
  const isAggData = event.node.group ? event.node.aggData : event.node.data;

  const isEditable =
    rowEditingData === null
      ? true
      : isAggData?.[FIELD_NAMES.SUMMARY_ID] ===
        rowEditingData?.[FIELD_NAMES.SUMMARY_ID];

  const field = event.colDef.field;
  const isFieldUnderlyingOrStrikeOrRatio =
    field === FIELD_NAMES.STRIKE ||
    field === FIELD_NAMES.UNDERLYING ||
    field === FIELD_NAMES.RATIO;

  if (!isEditable && !editable) return true;

  if (
    isFieldUnderlyingOrStrikeOrRatio &&
    event.node.level === 0 &&
    !event.node.group &&
    isEditable &&
    editable
  )
    return true; //! Passing True Prevent Cell From Being Editable

  /// special rule for tenor, volatility and underlying
  if (
    isSummaryLevel(event) &&
    !isSummaryLegLevel(event) &&
    hasThreeOrMoreLegs(event) &&
    UNEDITABLE_FIELDS_BASED_ON_MORE_THAN_TWO_LEGS.includes(field)
  )
    return false;

  const isLegOrSubLegEditableOrSummary =
    ((isSummaryLevel(event) || isSummaryLegLevel(event)) &&
      EDITABLE_FIELDS_BASED_ON_LEVEL[FIELD_NAMES.SUMMARY].includes(field)) ||
    ((isLegLevel(event) || isSummaryLegLevel(event)) &&
      EDITABLE_FIELDS_BASED_ON_LEVEL[FIELD_NAMES.LEG].includes(field)) ||
    (isSubLegLevel(event) &&
      EDITABLE_FIELDS_BASED_ON_LEVEL[FIELD_NAMES.SUB_LEG].includes(field));

  return !!isLegOrSubLegEditableOrSummary && isEditable && editable;
}

/**
 * Calculates the aggregated data for a given set of parameters.
 * @return {Object} The aggregated data, either leg_agg or summary_agg.
 */
export function aggFunc(params) {
  let result = {};
  if (params.nodes.length === 0) return result;
  const firstChildNodeData = params.nodes[0].group
    ? params.nodes[0].aggData
    : params.nodes[0].data;
  const legAgg = firstChildNodeData?.leg_agg ?? {};
  const summaryAgg = firstChildNodeData?.summary_agg ?? {};
  return firstChildNodeData.row_type === 2 ? legAgg : summaryAgg;
}

export const valueFormatterFunc = (event) => {
  const value = event.value;
  const field = event.colDef.field;

  const IS_LEG_OR_SUB_LEG_TO_HIDE_VALUES =
    isSubLegLevel(event) && HIDE_VALUES_IN_SUBLEGS.includes(field);

  if (IS_LEG_OR_SUB_LEG_TO_HIDE_VALUES) return "";
  let formattedValue = value;
  // /// ask and bid sides are repeated so we turn them to premium.

  if (
    field === FIELD_NAMES.COMPANY_PERCENTAGE ||
    field === FIELD_NAMES.COMPANY_VALUE
  ) {
    if (Number(formattedValue) === 0) return "";
    formattedValue = roundAndFormat(value, field);
  }

  // if (
  //   field === FIELD_NAMES.RATIO ||
  //   field === FIELD_NAMES.UNDERLYING ||
  //   field === FIELD_NAMES.STRIKE ||
  //   field === FIELD_NAMES.BID ||
  //   field === FIELD_NAMES.ASK
  // ) {
  //   formattedValue = formatNumber(formattedValue);
  // }

  if (typeof Number(formattedValue) === "number") {
    formattedValue = formatNumber(formattedValue);
  }
  formattedValue = formatUnit(formattedValue, event);
  return formattedValue;
};

export const valueParserFunc = (params) => {
  let newValue = params.newValue;
  newValue = Number(newValue);
  const field = params.colDef.field;
  const headerName = params.colDef.headerName;

  if (
    newValue < 0 &&
    (headerName === "Option 1" || headerName === "Option 2")
  ) {
    toast("Value Parser: strike cannot be negative", "error");
    return params.oldValue;
  }

  if (newValue < 0 && ALWAYS_POSITIVE_PARAMS.includes(field)) {
    // ALWAYS_POSITIVE_PARAMS.forEach((param) => {
    //   if (field === param) {
    //     // toast(`Value Parser: ${param.toUpperCase()} cannot be negative`, "error");
    //   }
    // });
    
    toast(`Negative not accepted`, "error");
    return params.oldValue;
  }

  if (
    newValue < 0 &&
    (isSubLegLevel(params) ||
      isLegLevel(params) ||
      isSummaryLegLevel(params)) &&
    ALWAYS_POSITIVE_LEG_AND_SUB_LEG_PARAMS.includes(field)
  ) {
    toast("leg/subleg premiums and volatilities cannot be negative", "error");
    return params.oldValue;
  }

  if (isNaN(newValue)) return params.oldValue;

  // special rule for ratio, if old value is negative then any new value must be turned negative as well
  if (field === "ratio" && params.oldValue < 0 && newValue > 0) {
    // Turn new value negative if the old value was negative and new value is positive
    newValue = -newValue;
  }

  return newValue;
};

export const cellStyleFunc = (params) => {
  if (COLUMN_CELL_TO_HAVE_RIGHT_BORDER.includes(params?.colDef?.field)) {
    return { borderRight: "2px solid var(--color-gray-2)" };
  }

  if (
    params?.colDef.field === FIELD_NAMES.ASK_DELTA ||
    params?.colDef.field === FIELD_NAMES.BID_DELTA
  ) {
    return { marginLeft: "10px" };
  }
};

export const headerCellStyle = (params) => {
  if (!COLUMN_CELL_TO_HAVE_RIGHT_BORDER.includes(params?.colDef.field))
    return "";
  return "header-right-border";
};

export const comparatorSorter = (item) => {
  const sorterForSpecificFields = {
    [FIELD_NAMES.SUMMARY_ID]: summaryIdComparator,
    [FIELD_NAMES.STRIKE]: strikeComparator,
    [FIELD_NAMES.TENOR]: tenorComparator,
    [FIELD_NAMES.TYPE]: typeComparator,
  };
  return sorterForSpecificFields[item.field]
    ? sorterForSpecificFields[item.field]
    : defaultcomparator;
};

//! Remove Sort From Ratio Column
export const disableSorterInSpecificColumns = (item) =>
  item.field !== FIELD_NAMES.RATIO;

export function reverseAggFunc(event) {
  const field = event.colDef.field;
  const isStrikeOrUnderlyingOrRatio =
    field === FIELD_NAMES.STRIKE ||
    field === FIELD_NAMES.UNDERLYING ||
    field === FIELD_NAMES.RATIO;
  const isVolatilityField =
    field === FIELD_NAMES.BID_PERCENTAGE ||
    field === FIELD_NAMES.ASK_PERCENTAGE;
  const isPremiumField = field === FIELD_NAMES.BID || field === FIELD_NAMES.ASK;
  const isQuantityField =
    field === FIELD_NAMES.BID_QTY || field === FIELD_NAMES.ASK_QTY;
  const isRiskFree = field === FIELD_NAMES.RISK_FREE;

  if (isLegLevel(event) || isSummaryLegLevel(event)) {
    if (isVolatilityField || isPremiumField)
      premiumOrVolatilityLegLevelreverseAggregate(event);
    if (isStrikeOrUnderlyingOrRatio)
      reverseAggregateLegRowStrikeOrUnderlying(event);
  }
  if (isSummaryLevel(event)) {
    if (isVolatilityField) reverseAggregateSummaryRowVolatility(event);
    if (isPremiumField) reverseAggregateSummaryRowPremium(event);
    if (isQuantityField) reverseAggregateSummaryRowQuantity(event);
    if (isRiskFree) reverseAggregateRf(event);
  }
}

export const storeInLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};
