import { Col } from "antd";
import {
  BUTTON_NAMES,
  FIELDS_LABEL,
  INPUT_KEYS,
  IS_CALL,
} from "constants/addOption";
import {
  InputNumberWithRadioButton,
  FormItem,
} from "./AddOptionHelperComponents";

function Strikes({ fetchedData, strikeValues, setStrikeValues }) {
  const onStrikeChange = (key, value, index) => {
    const data =
      fetchedData[FIELDS_LABEL.CONSTRAINTS.key] &&
      fetchedData[FIELDS_LABEL.CONSTRAINTS.key][FIELDS_LABEL.STRIKE.key][index][
        IS_CALL
      ];

    let buttonVal;

    if (data === null) buttonVal = null;
    if (data === 1) buttonVal = BUTTON_NAMES.CALL;
    if (data === 0) buttonVal = BUTTON_NAMES.PUT;

    if (key !== INPUT_KEYS.BUTTON) {
      setStrikeValues({
        ...strikeValues,
        [index]: {
          [INPUT_KEYS.BUTTON]: buttonVal,
          [IS_CALL]: data,
          ...strikeValues[index],
          [key]: value,
        },
      });
      return;
    }

    setStrikeValues({
      ...strikeValues,
      [index]: { ...strikeValues[index], [key]: value },
    });
  };

  return (
    <Col span={24}>
      <FormItem label={FIELDS_LABEL.STRIKE.label}>
        <div className="horizontal-scroll__container">
          {fetchedData[FIELDS_LABEL.CONSTRAINTS.key]?.[
            FIELDS_LABEL.STRIKE.key
          ].map((item, index) => {
            return (
              <InputNumberWithRadioButton
                key={index}
                isDisabled={item.value !== null}
                label={FIELDS_LABEL.STRIKE.label}
                value={
                  item.value === null && strikeValues
                    ? strikeValues[FIELDS_LABEL.STRIKE.key]?.[index]?.[
                        INPUT_KEYS.VALUE
                      ]
                    : item.value
                }
                onChange={(key, val) => onStrikeChange(key, val, index)}
                data={item}
              />
            );
          })}
        </div>
      </FormItem>
    </Col>
  );
}

export default Strikes;
