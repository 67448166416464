import { AgGridReact } from "ag-grid-react";
import { Row } from "antd";

import { useContext, useMemo } from "react";
import "ag-grid-enterprise";
import { GlobalContext } from "contexts/GlobalContext";
import { VOL_SURFACE_Data_COLS } from "constants/volSurface";
import { getRowClass, getRowStyle } from "constants/agGridCommonStyle";
import VolSurfaceDataFilters from "./VolSurfaceDataFilters";
import { getRowId } from "helpers/generalHelper";
import useVolSurfaceData from "./hooks/useVolSurfaceData";

export default function VolSurfaceDataTable() {
  const columnDefs = useMemo(() => VOL_SURFACE_Data_COLS, []);
   const {
     globalState: { dark_Theme },
   } = useContext(GlobalContext);
   
  const {
    gridHeight,
    gridRef,
    isLoading,
    surface,
    surfaces,
    volSurfaceData,
    selectedFilters,
    onSurfaceChange
  } = useVolSurfaceData();
 


  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    gridRef.current.api.showLoadingOverlay();
  };


  if (isLoading || !selectedFilters?.product_id) return null;

  return (
    <div style={{ width: "100%" }}>
      <VolSurfaceDataFilters
        riskFree={volSurfaceData?.risk_free}
        surfaces={surfaces}
        onChange={onSurfaceChange}
        value={surface}
      />
      <Row justify={"start"} style={{ width: "100%" }}>
        <div
          className={`${
            dark_Theme || false
              ? "pricer-ag-grid__container_dark"
              : "pricer-ag-grid__container"
          }`}
          style={{
            height: gridHeight,
            width: "100%",
            padding: "5px 0px 5px 0px",
          }}
        >
          <AgGridReact
            getRowId={getRowId}
            gridOptions={{
              stopEditingWhenGridLosesFocus: false,
            }}
            onGridReady={onGridReady}
            headerHeight={30}
            rowHeight={30}
            rowData={volSurfaceData.main_control_table}
            getRowStyle={(params) => {
              if (params.data.field !== "spread") {
                return {
                  borderTop: "none",
                  borderBottom: "none",
                };
              }
              getRowStyle(params);
            }}
            getRowClass={getRowClass}
            ref={gridRef}
            stopEditingWhenCellsLoseFocus={true}
            columnDefs={columnDefs}
            groupDefaultExpanded={1}
            suppressMovableColumns={true}
            suppressContextMenu
            className={`ag-theme-alpine pricer-ag-grid__table ${
              dark_Theme ? " ag-theme-balham-dark" : "ag-theme-balham"
            }`}
          />
        </div>
      </Row>
    </div>
  );
}
