import React from "react";
import ReactDOM from "react-dom/client";

import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import 'antd/dist/antd.css';
import "antd/dist/antd.min.css";
import { createBrowserHistory } from "history";
import "bootstrap/dist/css/bootstrap.min.css";
// Style
import "./styles/index-sass.scss";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/Common/ErrorFallback";

if (
  process.env.REACT_APP_SENTRY_DNS &&
  (process.env.REACT_APP_NODE_ENV === "production" ||
    process.env.REACT_APP_NODE_ENV === "staging")
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    environment: process.env.REACT_APP_NODE_ENV,
    integrations: [
    
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const customHistory = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode> // in StrictMode the react-beautiful-dnd library (for drag and drop teamRun tables in Home page) will not work, and strictMode is only for Development, it has no effect no production
  <ErrorBoundary
    onReset={() => window.location.replace("/")}
    FallbackComponent={ErrorFallback}
  >
    <BrowserRouter history={customHistory}>
      <App />
    </BrowserRouter>
  </ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
