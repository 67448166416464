import { useEffect, useMemo } from "react";
// Context
import { GEEKS_USER } from "constants/profile";
import { COMPANY_ID, TRIGGER_ID, USER_ID } from "constants/teamRun";
import { getDataFromLocalStorage } from "helpers/generalHelper";
import usePusher from "./usePusher";
const geeksUser = getDataFromLocalStorage(GEEKS_USER);

const useVolSurfaceWebSocket = (selectedFilters, handleRefreshTable) => {
    const { isConnected, subscribe, unsubscribe } = usePusher();

  const SOCKET_EVENT_DATA = useMemo(
    () => ({
      CHANNEL_NAME: `vol-surface.${geeksUser?.[COMPANY_ID]}`,
      EVENT_NAME: "vol-surface-notification",
    }),
    []
  );


  useEffect(() => {
    if (!isConnected) return;
    const handleNotification = (data) => {
        if (data[TRIGGER_ID] !== geeksUser[USER_ID]) {
          if (
            data.product_contract_style_id.toString() ===
            selectedFilters?.product_id.toString()
          ) {
            handleRefreshTable();
          }
        }
    } 
     subscribe(
       SOCKET_EVENT_DATA.CHANNEL_NAME,
       SOCKET_EVENT_DATA.EVENT_NAME,
       handleNotification
     );

     return () => {
       unsubscribe(
         SOCKET_EVENT_DATA.CHANNEL_NAME,
         SOCKET_EVENT_DATA.EVENT_NAME,
         handleNotification
       );
     };
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters, isConnected, subscribe, unsubscribe]);
};

export default useVolSurfaceWebSocket;
